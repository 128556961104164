@mixin grid() {
  display: grid;
  display: -ms-grid;
}

@mixin grid-template($rows, $columns) {
  grid-template-columns: $columns;
  -ms-grid-columns: $columns;
  grid-template-rows: $rows;
  -ms-grid-rows: $rows;
}

@mixin grid-placement($row, $column, $rowSpan: 1, $columnSpan: 1) {
  grid-column: $column / span $columnSpan;
  -ms-grid-column: $column;
  -ms-grid-column-span: $columnSpan;
  grid-row: $row / span $rowSpan;
  -ms-grid-row: $row;
  -ms-grid-row-span: $rowSpan;
}
