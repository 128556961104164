// OVERRIDES REACT CALENDAR
.react-date-picker__calendar {
  & .react-calendar {
    @include Lato();
    @include dropShadowForm();

    border-color: $form-input-outline;
    border-radius: 10px;
    padding: 20px;

    &__tile {
      padding: 1.5em 0.5em;
      border-radius: 50%;
    }

    &__month-view {
      &__weekdays {
        font-size: $font-size-x-small;
      }
    }
  }
}
