.ArticleSection {
  width: 100%;
  padding: 50px 0 0;
  overflow: hidden;
  align-items: center;
  justify-items: center;

  @include grid();
  @include grid-template(auto auto 1fr, 100%);

  @include breakpoint($sm) {
    @include grid-template(auto 1fr, 1fr auto);

    justify-items: start;
  }

  &__swiper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    @include grid-placement(2, 1);

    @include breakpoint($sm) {
      @include grid-placement(2, 1, 1, 2);
    }

    &__bullets {
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;

      &__bullet {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        margin-right: 15px;
        padding: 0;
        border-style: solid;
        border-width: 1px;
      }
    }

    & .swiper-container {
      width: 100%;

      & .swiper-wrapper {
        padding-bottom: 20px;

        & .swiper-slide {
          height: auto;
        }
      }
    }
  }

  & h1 {
    padding: 0 $spacing-small $spacing-medium;

    @include breakpoint($sm) {
      padding: 0 $spacing-small $spacing-medium 50px;
    }
  }

  &__title {
    @include grid-placement(1, 1);

    &__brand {
      text-transform: uppercase;
      padding: 0 $spacing-small;

      @include breakpoint($sm) {
        padding: 0 $spacing-small 0 50px;
      }
    }
  }

  @include breakpoint($sm) {
    flex-direction: row;

    & h1 {
      padding-bottom: 0;
    }
  }

  &__button {
    height: 50px;
    min-width: 215px;
    margin-right: 0;
    justify-items: center;

    @include grid-placement(3, 1);

    @include breakpoint($sm) {
      @include grid-placement(1, 2);

      margin: 0 60px;
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 0 0 3px;
    overflow: visible;

    &__container {
      max-width: 100%;

      @include breakpoint($sm) {
        padding: 20px 0 0 50px;
      }

      @include grid-placement(2, 1, 1, 2);
    }

    @include breakpoint($sm) {
      flex-direction: row;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      justify-content: flex-start;
      align-items: stretch;

      @include grid-placement(2, 1);
    }

    &__item {
      padding: 30px 30px 30px 0;
    }
  }

  &__slide {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
