.RegisterStudent {
  &__header {
    color: $white;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint($sm) {
      flex-wrap: nowrap;
    }

    &__left {
      order: 2;
      width: 100%;
      padding: 30px 30px 51px;

      @include breakpoint($sm) {
        padding: 40px;
        order: 1;
      }

      @include breakpoint($md) {
        padding: 100px 60px;
      }
    }

    &__right {
      order: 1;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      padding-top: 60.26%;

      @include breakpoint($sm) {
        padding-top: 0;
        order: 2;
        max-width: 40%;
      }
    }
    &__text {
      a {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }
}
