.HeaderNotifications {
  display: flex;
  justify-content: center;
  align-items: center;

  @include breakpoint($sm) {
    position: relative;
  }

  &__icon {
    position: relative;
    border-color: inherit;

    &--active {
      &:before {
        content: "";
        height: 5px;
        width: 5px;
        background-color: #ff672f;
        border-radius: 50%;
        position: absolute;
        right: 9px;
        top: 4px;
        border-width: 2px;
        border-style: solid;
        border-color: inherit;
      }
    }
  }

  &__menu {
    position: fixed;
    z-index: 5;
    top: 70px;
    flex-direction: column;
    left: 0;
    right: 0;
    bottom: 0;
    background: $white;
    border-radius: unset;
    box-shadow: 0 9px 10px -3px rgba(0, 0, 0, 0.4);
    display: none;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    @include IEFix() {
      width: 400px;
      height: 600px;
    }

    @include breakpoint($sm) {
      top: calc(100% + 20px);
      transform: translateX(-80%);
      right: unset;
      left: 90%;
      height: unset;
      border-radius: 10px;
      position: absolute;
      bottom: unset;
      max-height: 50vh;
    }

    &__items {
      border-top: 1px solid $dark-grey;
      overflow-y: auto;
      position: relative;
      flex: 1;

      &__noItems {
        text-align: center;
        padding: 20px;
      }
    }

    &__buttons {
      display: flex;
      justify-content: space-around;
      flex-direction: row;
      align-items: center;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      padding: $spacing-large;
      height: 100px;

      &__view,
      &__clear {
        margin: $spacing-small;

        @include breakpoint($sm) {
          margin: 0;
          height: unset;
        }
      }

      &__view {
        margin-right: 10px;
      }

      &__clear {
        width: 150px;
      }
    }
  }

  &::before {
    display: none;
    box-sizing: border-box;
    content: "";
    height: 10px;
    width: 10px;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 18px solid $white;
    position: absolute;
    bottom: -21px;
    z-index: 6;
  }

  &--active {
    transition: all 0.3s ease-in-out;
    display: flex;
    opacity: 1;

    &::before {
      transition: all 0.3s 2s ease-in-out;
      display: none;
      opacity: 1;

      @include breakpoint($sm) {
        display: initial;
      }
    }

    .HeaderNotifications__menu {
      display: flex;
      opacity: 1;
    }
  }
}
