@import "./components/notificationSettings";

.Notifications {
  padding: 30px;
  display: flex;
  flex-direction: column;

  @include breakpoint($sm) {
    padding: 10% 15%;
  }

  &__loader {
    height: 200px;
    display: flex;
    justify-content: center;

    & .Loader {
      max-width: 200px;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    & button {
      height: 50px;
    }

    &__title {
      margin: 5px 5px 15px 0;
    }
  }

  &__notificationList {
    flex: 1;

    & .NotificationItem {
      width: 100%;
    }
  }
}
