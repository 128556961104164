.EditProfile {
  max-width: 800px;
  padding: 0 30px;
  margin: auto;

  &__loader {
    height: 200px;
    display: flex;
    justify-content: center;
    margin-right: 50px;

    & .Loader {
      max-width: 200px;
    }
  }

  @include breakpoint($sm) {
    padding: 0 150px;
  }

  &__photoAndName {
    display: flex;
    margin: 40px 0;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    @include breakpoint($sm) {
      flex-direction: row;
      margin: 40px 0;
    }
    &__name {
      text-align: center;
      margin-top: 30px;
      @include breakpoint($sm) {
        margin-left: 50px;
        text-align: left;
      }
    }
  }
}
