@import "./../../node_modules/react-big-calendar/lib/sass/styles.scss";
@import "./../../node_modules/react-date-picker/dist/DatePicker.css";
@import "./../../node_modules/react-calendar/dist/Calendar.css";

.rbc-calendar {
  height: 100%;

  @include breakpoint($sm) {
    height: auto;
  }
}

div.rbc-date-cell {
  padding-bottom: 5px;
}

.rbc-header,
.rbc-month-view,
.rbc-time-view,
.rbc-month-row,
.rbc-time-row {
  border: none;
  z-index: 0;

  & + .rbc-header {
    border: none;
  }
}

.rbc-month-view,
.rbc-time-view {
  border-color: inherit;
  background-color: $white;
  height: unset;

  @include IEFix() {
    flex: 1 1 500px;
  }
}

.rbc-header {
  text-transform: uppercase;

  & > a {
    cursor: default;
  }

  & p:last-of-type {
    color: #757575;
  }

  & p:first-of-type {
    font-weight: 400;
    color: $font-calendar-grey;
  }

  & p {
    height: 24px;
    margin-bottom: 5px;
  }
}

.rbc-day-bg {
  border-right: 1px solid #ddd;

  & + .rbc-day-bg {
    border-left: none;
  }
}

.rbc-header,
.rbc-date-cell {
  color: $font-calendar-grey;

  & a {
    cursor: default;
  }
}

.rbc-time-gutter {
  color: #757575;
  font-size: 12px;
  width: 58.3594px;
  min-width: 58.3594px;
  max-width: 58.3594px;
  margin-top: -6px;

  & .rbc-timeslot-group .rbc-time-slot {
    display: flex;
    justify-content: flex-end;
    padding-right: $spacing-small;
  }
}

.rbc-day-slot.rbc-time-column .rbc-timeslot-group {
  min-height: 60px;
}

.rbc-time-header-content,
.rbc-time-header.rbc-overflowing {
  border-left: none;
  border-right: none;
}

.rbc-time-header.rbc-overflowing,
.rbc-time-header {
  border-bottom: 2px;
  border-bottom: inherit;
  border-bottom: solid;
}

.rbc-time-header .rbc-label {
  padding: 0 2.5px;
}

.rbc-label {
  cursor: default;
}

.rbc-time-content {
  border-top: none;
  padding-top: 16px;
  border-right: 1px solid #ddd;
}

.rbc-time-gutter .rbc-timeslot-group {
  border-bottom: none;
  min-height: 60px;
}

.rbc-time-slot + .rbc-time-slot {
  display: none;
}

.rbc-month-row {
  flex: unset;
  min-height: 55px;
  height: 100%;

  @include breakpoint($sm) {
    height: calc(
      (100vw - 420px) / 7
    ); // This is bad, as soon as I find a better way I'll do that
  }
}

.rbc-month-row,
.rbc-time-row {
  border: 1px solid #ddd;
  border-right: none;
  display: flex;

  & .rbc-row-content {
    height: 100%;
    flex: 1;
    font-size: 14px;

    & .rbc-row {
      flex: 1;
      display: flex;
    }
  }

  & + .rbc-month-row,
  & + .rbc-time-row {
    border-top: none;
  }
}

.rbc-month-row {
  border-top-width: 2px;
  border-top-color: inherit;
  border-top-style: solid;
}

.rbc-today {
  background-color: unset;
}

.rbc-day-slot {
  border-top: 1px solid #ddd;
}

.rbc-time-view .rbc-allday-cell {
  height: auto;

  & .rbc-row-content {
    height: 100%;
    max-height: 60px;
    overflow: auto;

    @include IEFix() {
      height: auto;
      overflow: visible;
      max-height: initial;
    }
  }

  & .rbc-row-bg {
    border: 1px solid #ddd;
    border-bottom: none;

    & .rbc-day-bg {
      border-right: 1px solid #ddd;
    }

    .rbc-day-bg:last-of-type {
      border-right: none;
    }
  }
}

.rbc-date-cell {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding: 2px;

  &:not(.rbc-now) {
    & a {
      padding: 1px 3px;
    }
  }

  &.rbc-now {
    padding: 0 0 2px;

    & a {
      color: $white;
      line-height: 0;
      margin-top: 3px;
      margin-right: 3px;
      align-self: flex-end;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      background-color: #0f2245;
      border-radius: 50%;
    }
  }
}

.rbc-today {
  & a div {
    display: flex;
    flex-direction: column;
    align-items: center;

    & p:last-of-type {
      color: $white;
      line-height: 0;
      display: block;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      background-color: #0f2245;
      border-radius: 50%;
    }
  }
}

.rbc-event {
  border-radius: 3px;

  &:focus {
    margin: 0;
    outline: none;
    border: 1px solid black;
  }
}

.rbc-row {
  margin-bottom: 1px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.rbc-current-time-indicator {
  display: none;
}

.rbc-off-range-bg {
  background: none;
}

.rbc-off-range {
  opacity: 0.5;
}

.rbc-selected-cell,
.rbc-slot-selection {
  background: #e7e7e7;
  color: #757575;
}

.rbc-row-segment {
  padding: 0;
}

.rbc-day-slot {
  .rbc-event {
    flex-direction: column-reverse;
    justify-content: flex-end;
    padding: 6px 6px 4px;
    border: none;
  }

  .rbc-event-content {
    flex: 0 1;
    height: auto;
    min-height: 14px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 0;
    text-overflow: ellipsis;
    max-width: 100%;
  }

  .rbc-events-container {
    margin-right: 0;
  }
}

.rbc-event-label {
  max-width: 100%;
}

.rbc-event-label,
.rbc-event-content {
  font-size: 14px;
}
