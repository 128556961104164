.Form {
  &__error {
    color: $error;
    margin: 20px 0;
  }

  &__fieldRow {
    @include breakpoint($sm) {
      display: flex;
    }

    &--flex {
      display: flex;
    }

    &--flexColumn {
      display: flex;
      flex-direction: column;
    }

    &--alignCenter {
      align-items: center;
    }

    &--justifyCenter {
      justify-content: center;
    }

    &--spaceBetween {
      justify-content: space-between;
    }

    &--wrap {
      flex-wrap: wrap;
    }

    &--marginSpacing {
      margin: 30px 0;
    }

    &--spacing120 {
      height: 120px;
    }

    &--lineDivide {
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: solid thin rgba($font-secondary-grey, 0.5);
    }

    > div {
      margin-right: 10px;
      height: auto;
      width: 100%;

      &:last-child {
        width: 100%;
        margin-right: 0;
      }
    }

    &__item {
      &--fullWidth {
        * {
          width: 100%;
        }
      }
    }
  }

  &Input {
    &__label {
      &--marginBottom {
        margin-bottom: 10px;
      }
    }
  }

  &__section {
    border-bottom: solid thin $font-secondary-grey;
    padding-bottom: 25px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;

    &__heading {
      margin: 40px 0;
    }
  }

  &__message {
    font-size: $font-size-small;
    border-radius: 5px;
    border-top-left-radius: 0;
    padding: 10px;
    background: white;
    border: solid thin;
    white-space: pre-line;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;

    &--success {
      border-color: $events-green;
      color: $events-green;
    }

    &--fail {
      border-color: $form-error;
      color: $form-error;
    }
  }
}
