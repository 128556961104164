.UserInformationFormFields {
  &__section {
    padding-bottom: 10px;
    margin: 50px 0;
    border-bottom: solid thin;

    &__heading {
      margin: 30px 0;
    }
  }

  &__subSection {
    margin: 20px 0 50px;
  }
}
