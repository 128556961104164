.ActiveFilterList {
  display: flex;
  flex-wrap: wrap;

  &__element {
    border-radius: 30px;
    border-width: 1px;
    border-style: solid;
    height: 60px;
    padding: 12px 28px;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-transform: capitalize;
    min-width: 150px;
    margin-right: 12px;
    margin-bottom: 12px;
    color: $white;
    padding-right: 70px;
  }

  &__delete {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    @include breakpoint($sm) {
      border: 1px solid $white;
    }
  }
}
