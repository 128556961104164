.ContactSlider {
  display: flex;
  flex-flow: column;
  padding: 46px 46px 43px;
  color: $white;

  &__toolbar {
    @include grid();
    @include grid-template(1fr auto, auto auto);

    @include IEFix() {
      @include grid-template(1fr auto, 20% 70%);
    }

    width: 100%;

    &__selector {
      display: none;
      align-items: flex-start;
      width: 160px;
      align-self: center;
      justify-self: end;

      @include IEFix() {
        margin-left: calc(100% - 60px);
      }

      @include grid-placement(1, 2);

      @include breakpoint($sm) {
        display: flex;
      }

      &__middle {
        flex: 1;
        margin: 0 -30px;
        height: 60px;
        min-width: 100px;
        border-top: 1px solid;
        border-bottom: 1px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 120px);
      }

      &__button {
        z-index: 1;
        border-radius: 50%;
        height: 60px;
        width: 60px;
      }
    }

    &__regionSelect {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin: 8px 0;
      overflow: hidden;

      @include grid-placement(1, 1, 1, 2);

      &__dropdown {
        display: flex;
        align-items: center;
        color: $white;

        &__title {
          font-size: 36px;
        }

        &__button {
          align-self: flex-end;
          margin-bottom: 5px;
          transition: all 0.3s ease-in-out;

          &--active {
            transform: scaleY(-1);
            margin-bottom: 8px;
          }
        }
      }

      &__regionList {
        display: flex;
        flex-wrap: nowrap;
        width: 100%;
        opacity: 0;
        height: 0;
        transform: rotateX(-90deg) scale(0.95) translateY(-10px);
        transition: all 0.5s ease-in-out;
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        margin: 15px 0;
        transform-origin: 0 0;

        @include grid-placement(2, 1, 1, 2);

        &--active {
          opacity: 1;
          height: 58px;
          transform: rotateX(0) scale(1) translateY(0);
          transition: all 0.6s ease-in-out;
        }

        & > button:first-of-type {
          padding-left: 0;
        }

        &__button {
          color: $white;
          opacity: 0.5;

          & h2 {
            font-size: 26px;
            white-space: nowrap;
          }

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  &__noContacts {
    height: 120px;
  }

  & .swiper-container {
    margin: unset;
    min-height: 445px;
    overflow: visible;
  }

  &__slides {
    display: flex;
    align-items: center;
    flex-flow: column;

    & .ContactItem {
      margin-bottom: 20px;
      width: 100%;
      max-width: 340px;
    }
  }
}
