.SocialFeed {
  display: flex;
  flex-direction: column;
  width: calc(100% - 60px);

  @include breakpoint($sm) {
    width: calc(100% - 100px);
  }

  &__header {
    margin-bottom: 20px;
  }

  &__items {
    @include grid();
    @include grid-template(
      calc(50vw - 40px),
      repeat(auto-fit, calc(50vw - 40px))
    );

    grid-gap: 10px;
    width: 100%;

    @include breakpoint($sm) {
      @include grid-template(
        calc(33vw - 130px),
        repeat(auto-fit, calc(33vw - 130px))
      );
    }

    @include IEFix() {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
