.BrandsSlider {
  padding-top: 52px;
  margin-left: 0;

  @include breakpoint($sm) {
    margin-left: 50px;
  }

  &__loader {
    height: 200px;
    display: flex;
    justify-content: center;
    margin-right: 50px;

    & .Loader {
      max-width: 200px;
    }
  }

  &__toolbar {
    padding-left: $mobile-page-margin;
    padding-right: $mobile-page-margin;
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
    flex-direction: column;

    @include breakpoint($sm) {
      margin-left: 0;
      margin-right: 50px;
      flex-direction: row;
    }

    &__title {
      & p {
        margin: 0;
  
        @include breakpoint($sm) {
          margin: unset;
        }
      }

      & h1 {
        margin-bottom: 5px;
  
        @include breakpoint($sm) {
          margin: 0;
        }
      }
    }

    &__selector {
      display: flex;
      align-items: flex-end;
      align-self: flex-end;
      height: 45px;

      @include breakpoint($sm) {
        height: 60px;
        align-self: flex-end;
        margin-bottom: 2px;;
      }

      &__count {
        flex: 1;
        border-top: 1px solid $dark-grey;
        border-bottom: 1px solid $dark-grey;
        justify-content: center;
        width: calc(100% - 120px);
        display: flex;
        align-self: flex-end;
        align-items: center;
        height: 45px;
        min-width: 130px;
        margin: 0 -30px;

        @include breakpoint($sm) {
          margin: 0 -30px;
          min-width: 160px;
          height: 60px;
          padding: 0;
        }
      }

      &__button {
        z-index: 1;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        height: 45px;
        width: 45px;

        @include breakpoint($sm) {
          height: 60px;
          width: 60px;
        }
      }
    }
  }
}
