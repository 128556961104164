.HeaderSearch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: 1;

  &__input {
    color: $white;
    border-radius: 0;
    border: none;
    background-color: transparent;
    height: 25px;
    flex: 0 1 0;
    font-size: $font-size-small;
    transition: flex-basis 0.8s ease-in-out;
    overflow: hidden;
    appearance: none;

    @include IEFix() {
      flex: unset;
      width: 0;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 10px;
      width: 10px;
      display: block;
      background-color: aliceblue;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
      background-repeat: no-repeat;
      background-size: 10px;
      border-radius: 10px;
    }

    &--active {
      flex-basis: 426px;
      display: initial;
      border-bottom: 1px solid $white;

      @include IEFix() {
        flex: unset;
        width: 300px;
      }
    }
  }

  &--mobile {
    position: relative;
    border: 1px solid $white;
    border-radius: 50px;

    & button {
      position: relative;
      margin-left: 15px;
    }

    & input {
      color: $white;
      background-color: transparent;
      height: 40px;
      flex: 1;
      display: initial;
      font-size: $font-size-default;
      transition: flex-basis 0.8s ease-in-out;
      overflow: hidden;
      padding-inline-start: 10px;
      padding-inline-end: 10px;
    }
  }
}
