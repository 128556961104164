.FormProgressBar {
  @include dropShadowForm();

  margin: 40px;
  background: white;
  padding: 30px;
  border-radius: 5px;

  &__headings {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    @include breakpoint($sm) {
      justify-content: space-between;
    }

    &__title {
      display: none;

      @include breakpoint($sm) {
        display: initial;
      }
    }
  }

  &__bar {
    height: 8px;
    position: relative;
    background: $form-input-outline;
    border-radius: 4px;
    overflow: hidden;

    &__progress {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background: black;
      border-radius: 5px;
    }
  }
}
