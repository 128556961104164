.Calendar {
  padding: 0;
  position: relative;

  @include breakpoint($sm) {
    padding: 50px;
  }

  &__modal {
    position: fixed;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 95%;
    max-width: 80vw;
    max-height: 80vh;
    z-index: 5;
    color: $white;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);

    @include breakpoint($sm) {
      width: 423px;
      left: calc(50% + 120px);
      top: 50%;
    }
  }

  &__event {
    height: 100%;
    width: 100%;
    overflow: auto;

    &__head {
      padding: 25px 30px 16px;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;

      &__close {
        display: flex;
        align-items: center;
        justify-content: center;

        & svg {
          transform: rotate(45deg);
        }
      }

      &__info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        flex: 1;
        overflow: hidden;
        margin-right: 10px;

        &__title {
          @include LatoBold();
        }

        &__brandName {
          padding: 3px 8px;
          border-radius: 2px;
        }
      }

      & p {
        margin-bottom: $spacing-small;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%;
      }

      & button {
        justify-self: end;
        height: 40px;
        width: 40px;
        border-width: 1px;
        border-style: solid;
        border-radius: 50%;

        & > div {
          display: flex;
          justify-content: center;
          align-items: center;
          transform: rotate(45deg);
        }
      }
    }

    &__dates {
      padding: 20px 30px;
      border-top: 1px solid #888;
      border-bottom: 1px solid #888;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;

      & > p:last-of-type {
        text-align: right;
      }

      @include breakpoint($sm) {
        flex-direction: row;
      }
    }

    &__details {
      padding: 16px 30px 40px;

      & > p {
        max-height: 150px;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
      }

      & button {
        margin-top: 30px;
        height: 50px;
        width: 207px;
        fill: $white;
      }
    }
  }

  &__seeMore {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    max-height: 400px;
    overflow: auto;

    &__eventWrap {
      width: 86%;
      overflow: scroll;
      border-right: 1px solid;
    }

    &__event {
      width: 100%;
      padding: $spacing-default;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-items: start;
      border-bottom: 1px solid #e5e3e3;

      &__brandName {
        padding: 3px 5px;
        border-radius: 2px;
      }

      & p {
        margin-bottom: $spacing-small;
      }
    }

    &__closeButton {
      position: absolute;
      top: 10px;
      right: 10px;
      height: 40px;
      width: 40px;
      flex: 0 0 40px;
      border-width: 1px;
      border-style: solid;
      border-radius: 50%;

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(45deg);
      }
    }
  }

  &__toolbar {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__dropdown {
      margin: 0;
    }

    &__top {
      @include grid();
      @include grid-template(100%, 1fr auto auto auto);

      align-items: center;
      background-color: transparent;
      margin-bottom: 0;
      padding: $spacing-default 20px $spacing-default 25px;

      @include breakpoint($sm) {
        background-color: $white;
        margin-bottom: $spacing-default;
        padding: $spacing-default;
      }

      &__title {
        @include grid-placement(1, 1);

        display: flex;
        align-items: center;
      }

      &__radio {
        display: none;

        &--table {
          @include grid-placement(1, 3);
        }

        &--folder {
          @include grid-placement(1, 4);
        }

        &:checked {
          & + label {
            background-color: $grey;
          }
        }

        & + label {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          flex: 0 0 40px;
          border-radius: 50%;
        }
      }

      &__input {
        border-style: solid;
        height: 38px;
        padding: $spacing-small;
        flex: 0 1 340px;
        border-radius: 20px;
        box-shadow: none;
        margin-right: 10px;
        font-size: $font-size-default;
        padding-inline-start: $spacing-default;
        padding-inline-end: $spacing-default;
        opacity: 0.3;
        transition: all 0.2s;
        display: none;
        border-width: 1px;
        padding-left: 38px;
        -webkit-border-radius: 20px;
        -webkit-appearance: none;
        appearance: none;

        @include breakpoint($md) {
          width: 340px;
        }

        &__container {
          margin-left: 10px;
          position: relative;
          display: none;

          @include grid-placement(1, 2);

          @include breakpoint($sm) {
            display: block;
          }
        }

        &__icon {
          position: absolute;
          top: 13px;
          left: 17px;
          width: 13px;
          height: 13px;
          opacity: 0.4;
        }

        @include breakpoint($sm) {
          display: block;
        }

        &:focus {
          opacity: 1;
        }
      }
    }

    &__bottom {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      background-color: $white;
      width: 100%;
      min-height: unset;
      padding: $spacing-small 25px;
      align-items: center;

      @include breakpoint($sm) {
        min-height: 140px;
        padding: $spacing-default 40px;
      }

      @include breakpoint($lg) {
        justify-content: space-between;
      }

      &__tagFilter {
        display: none;
        align-items: center;
        justify-content: center;
        flex: 0.45 1 200px;
        padding: 4px;

        & .FormElement {
          margin: 0;
        }

        @include breakpoint($sm) {
          display: flex;
          flex: 1;
          min-width: 250px;
        }

        & p {
          margin-right: $spacing-default;
        }
      }

      &__monthSelector {
        display: flex;
        flex: 1;
        align-items: center;
        margin-right: 0;
        padding: 4px 0 0;
        position: relative;
        justify-content: space-between;
        height: 60px;

        @include breakpoint($sm) {
          margin-right: $spacing-default;
          flex: 1;
          padding: 4px 0;
          min-width: 330px;
        }

        & p {
          position: absolute;
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border-radius: 30px;
          top: 0;
          right: 0;

          @include breakpoint($sm) {
            border: 1px solid $dark-grey;
          }
        }

        & button {
          z-index: 1;
          border-radius: 50%;
          padding: 0;
          height: unset;
          width: unset;

          @include breakpoint($sm) {
            border-style: solid;
            border-width: 1px;
            height: 60px;
            width: 60px;
          }
        }
      }
    }
  }
}
