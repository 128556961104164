@import "./contactSlider";
@import "./contactItem";

.Contact {
  height: 100%;

  &__banner {
    @include grid();
    @include grid-template(auto, 1fr);

    padding: 0;
    align-content: center;
    align-items: center;
    overflow: hidden;
    height: 225px;

    @include breakpoint($sm) {
      height: 272px;

      @include grid-template(auto, 0.35fr 0.65fr);
    }

    & .Contact__banner__info {
      @include grid-placement(1, 1);

      padding: 30px 46px;
      max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      position: relative;

      @include breakpoint($sm) {
        padding: 46px;

        @include grid-placement(1, 1, 1, 2);
      }

      & h1 {
        white-space: nowrap;
        margin-bottom: 15px;
      }
    }

    &__image {
      z-index: 0;
      overflow: visible;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 200px;

      @include grid-placement(1, 1);

      @include breakpoint($sm) {
        @include grid-placement(1, 2, 1, 3);

        height: 262px;
      }

      & img {
        width: 130%;
        height: unset;
        z-index: -1;
      }
    }
  }
}
