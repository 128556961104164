.NeedHelpAccordion {
  font-size: 18px;
  margin-bottom: 10px;

  @include breakpoint($sm) {
    margin-bottom: 20px;
  }

  &__topBar {
    height: 100px;
    width: 100%;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    transition: margin-bottom 0.3s 0.4s ease-in-out;

    & button {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s ease-in-out;
      border-width: 1px;
      border-style: solid;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      z-index: 1;
    }
  }

  &__details {
    max-height: 0;
    width: 100%;
    opacity: 0;
    padding: 10px 30px;
    background-color: $white;
    transition: all 0.4s ease-in-out;
    z-index: 0;

    & p {
      opacity: 0;
      transform: translateY(20px);
      transition: all 0.2s 0s ease-in-out;
    }

    a {
      text-decoration: underline;
    }

    ul,
    ol {
      padding-left: 30px;
    }

    ul {
      list-style: initial;
    }

    ol {
      list-style-type: decimal;
    }
  }

  &--open {
    .NeedHelpAccordion__details {
      max-height: 1500px; // arbitrarily large - used for animation purposes
      opacity: 1;
      transition: all 0.4s 0.4s ease-in-out;

      @include breakpoint($sm) {
        padding: 30px;
      }

      & p {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.4s 0.1s ease-in-out;
      }
    }

    .NeedHelpAccordion__topBar {
      margin-bottom: 10px;
      transition: margin-bottom 0s 0s ease-in-out;

      @include breakpoint($sm) {
        margin-bottom: 20px;
      }

      & button {
        transform: rotateZ(-45deg);
      }
    }
  }
}
