@import "./LoginHeader/login-header";
@import "./LoginForm/login-form";
@import "./LoginSlider/login-slider";

.Login {
  @include grid();
  @include grid-template(auto 1fr, 100%);

  @include IEFix() {
    @include grid-template(auto auto, 100%);
  }

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%;

  @include breakpoint($sm) {
    @include grid-template(100%, 40vw 60vw);

    @include IEFix() {
      @include grid-template(100%, 0.45fr 0.55fr);
    }

    overflow: hidden;
  }

  &__right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: visible;
    width: 100%;
    height: 100vh;

    @include grid-placement(2, 1);

    @include breakpoint($sm) {
      display: block;
      overflow: hidden;
      height: unset;

      @include grid-placement(1, 2);
    }
  }

  &__left {
    @include grid-placement(1, 1);

    z-index: 1;
    width: 100%;
    padding: 8%;
    overflow-y: unset;

    @include IEFix() {
      padding: 0 8%;
    }

    @include breakpoint($sm) {
      padding: $spacing-xlarge;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      overflow-y: auto;

      @include IEFix() {
        padding: 0 $spacing-xlarge;
        min-width: auto;
      }
    }

    &__content {
      max-width: 500px;

      @include IEFix() {
        padding: 0;
        margin: 60px 0;
      }
    }

    &__brandLogo {
      height: 100px;
      margin-bottom: 20px;
      min-height: 100px;

      & img {
        height: 100%;
        width: auto;
        min-height: 50px;
      }
    }

    &__loginHeader {
      padding-top: 0;
      max-width: 420px;

      @include breakpoint($sm) {
        max-width: unset;
      }
    }

    &__loginForm {
      max-width: 420px;
      margin-top: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint($sm) {
        max-width: unset;
        display: block;
        width: unset;
      }

      @include IEFix() {
        margin-bottom: 60px;
      }
    }
  }

  &__slide {
    width: 100%;
  }
}
