.FileViewFolder {
  @include grid();
  @include grid-template(130px 75px, 1fr auto);

  justify-items: center;
  align-items: center;
  cursor: pointer;

  @include breakpoint($phablet) {
    @include grid-template(190px 75px, 240px);
  }

  &__data {
    @include grid-placement(2,1);

    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__name {
      max-width: 90%;
      overflow: hidden;
      align-items: center;
      justify-content: center;
      text-align: center;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;

      @include IEFix() {
        text-overflow: ellipsis;
      }
    }

    &__items {
      font-size: 14px;
      text-align: center;
    }
  }

  &--failed {
    background: $form-input-outline;
    color: $font-calendar-grey;
    padding: 20px;
    border-radius: 5px;
    height: 150px;
  }

  & > svg {
    height: auto;
    width: 100px;
    margin: 0 auto $spacing-small;

    @include grid-placement(1,1,1,2);

    @include breakpoint($phablet) {
      height: auto;
      width: 200px;
    }
  }

  &__dots {
    @include grid-placement(2,2,2,1);

    width: 100%;
    display: flex;

    @include breakpoint($phablet) {
      display: none;
    }
  }
}
