.DocumentItemSection {
  @include grid();
  @include grid-template(1fr auto 1fr, auto 1fr);

  @include breakpoint($sm) {
    @include grid-template(auto 1fr, auto 1fr auto);
  }

  color: $primary-blue;
  padding-bottom: 50px;

  &__title {
    display: flex;
    text-transform: uppercase;
    flex: 1;
    align-items: center;
    letter-spacing: 2px;

    @include grid-placement(1, 1);
  }

  &__seperator {
    @include grid-placement(1, 2);

    align-self: center;
    height: 2px;
    margin: 0 0 0 10px;
    display: inline-block;

    @include breakpoint($md) {
      margin: 0 10px;
    }
  }

  &__seeAll {
    font-size: $font-size-default;
    justify-self: center;

    @include grid-placement(3, 1, 1, 2);
    @include LatoBold();

    @include breakpoint($sm) {
      @include grid-placement(1, 3);
    }

    &__icon {
      margin-left: 8px;
      width: 12px;

      @include breakpoint($sm) {
        display: none;
      }
    }
  }

  &__itemWrap {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;
    margin-right: 20px;

    @include grid-placement(2, 1, 1, 2);

    @include breakpoint($sm) {
      flex-direction: row;

      @include grid-placement(2, 1, 1, 3);
    }
  }

  &__noItems {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px 0;

    @include grid-placement(2, 1, 1, 2);

    @include breakpoint($sm) {
      @include grid-placement(2, 1, 1, 3);
    }
  }
}
