.Avatar {
  position: relative;

  &--large {
    width: 150px;
    height: 150px;
    min-width: 150px;
  }

  &__imageContainer {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
    background: $grey;

    &__initials {
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;

      & h1 {
        text-align: center;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 85px;
        padding: 45px;
        margin-bottom: 15px;
        color: #fff;
        font-family: "PlayfairBold";
      }
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.5);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.2s;
    }

    &--dropPhoto {
      &::after {
        opacity: 0.5;
      }

      .Avatar__imageContainer__image {
        transform: scale(1.3);
      }
    }

    &--loading {
      &::after {
        opacity: 1;
      }
    }

    &__image {
      pointer-events: none;
      transition: transform 0.2s;
      top: 0;
      margin: auto;
      height: 100%;
      border-radius: 50%;

      & > img {
        border-radius: 50%;
      }
    }
  }

  &__controls {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transition: background-color 0.2s, transform 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $white;
      cursor: pointer;
    }

    &:active {
      transform: translateY(3px) scale(0.95);

      .Avatar__controls__icon {
        transform: scale(0.9);
      }
    }

    &--dropPhoto {
      transform: translateY(3px) scale(1.1);
    }

    &--loading {
      @include loadingWheel;
    }

    &__icon {
      display: block;
      transition: transform 0.2s, fill 0.2s;
      fill: $white;
      opacity: 1;
      transition: opacity 0.2s;

      &--hide {
        opacity: 0;
      }
    }
  }
}
