.CheckRadioInput {
  margin: 20px 0;
  display: flex;
  align-items: center;

  &--left-margin-title {
    .CheckRadioInput__title {
      margin-left: 12px;
    }
  }

  &__checker {
    @include dropShadowForm();

    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: $form-input-outline;
    background: white;
    margin-right: 8px;
    cursor: pointer;
    transition: transform 0.2s;

    &--checkbox {
      border-radius: 4px;

      &::after {
        border-radius: 20%;
      }
    }

    &--radio {
      border-radius: 50%;

      &::after {
        border-radius: 50%;
      }
    }

    &::after {
      content: "";
      display: block;
      width: 40%;
      height: 40%;
      opacity: 0;
      transition: opacity 0.3s, background-color 0.2s, transform 0.3s;
      background-color: $grey;
      transform: scale(0);
    }

    &:hover {
      transform: scale(1.1);

      &::after {
        opacity: 1;
        transform: scale(1);
      }
    }

    &:active {
      transform: scale(1.4);

      &::after {
        transform: scale(1.4);
      }
    }

    &--checked {
      &::after {
        opacity: 1;
        background-color: $primary-blue;
        transform: scale(1);
      }
    }
  }

  &__title {
    margin-right: 25px;
    transition: transform 0.2s;
  }
}
