.NotificationItem {
  display: flex;
  align-items: center;
  padding: $spacing-small;
  border-bottom: 1px solid $dark-grey;
  position: relative;
  margin: 0;
  background-color: $white;
  max-width: 100vw;
  width: 100%;

  &--unread {
    background-color: #fff3ee;

    &::after {
      content: "";
      height: 10px;
      width: 10px;
      min-height: 10px;
      min-width: 10px;
      background-color: #ff672f;
      border-radius: 50%;
      margin-right: 15px;
    }
  }

  &__imageWrap {
    margin: 0 20px 0 15px;
  }

  &__image {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    min-width: 10%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &__details {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $font-primary-grey;
    margin-right: 10px;
    max-width: 75%;
    padding: 10px 0;
    overflow: hidden;

    &__item {
      margin-bottom: 4px;
      font-size: $font-size-small;
      text-align: left;
    }

    &__event {
      @include LatoBold;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
    }
  }
}
