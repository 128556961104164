//COLORS
$primary-blue: #0f2245;
$primary-gold: #b89e66;
$error: #b82034;
$white: #fff;
$black: #000;
$greyBlue: rgba(15, 34, 69, 0.1);
$greyD8: #d8d8d8;
$greyE7: #e7e8ec;
$darkGreen: #357a38;

// -- FONT
$font-primary-grey: #525257;
$font-secondary-grey: #6d7278;
$font-calendar-grey: #bebebe;
$header-primary-grey: #2b2c2f;

// -- NOTIFICATIONS
$notifications-yellow: #ffdb3e;
$notifications-orange: #ff672f;
$notifications-background-orange: #fff3ee;
// -- EVENTS
$events-blue: #afb1b3;
$events-green: #79ceb9;
$events-orange: #fd9f60;
$events-red: #ffa7a7;
// -- BASIC
$white: #fff;
$grey: #f5f5f5;
$dark-grey: #e4e4e4;
$very-light-grey: #ebe9e9;

// -- FORMS
$form-input-outline: #e2e2e2;
$form-input-outline-active: $primary-blue;
$form-error: #b82034;

// END COLORS

// SIZING
// -- FONT
$font-size-x-small: 8px;
$font-size-small: 13px;
$font-size-default: 16px;
$font-size-title-small: 16px;
$font-size-medium: 24px;
$font-size-large: 40px;
$font-size-title-large: 46px;
// -- WEIGHT
$regular: 400;
// PADDING, MARGIN
$spacing-small: 6px;
$spacing-default: 16px;
$spacing-medium: 18px;
$spacing-large: 22px;
$spacing-larger: 30px;
$spacing-xlarge: 64px;

$letter-spacing-heading: 2.4px;

// ALPHA
$light: 0.3;
$medium: 0.5;
$dark: 0.7;

//BREAKPOINTS
$ip: 375px;
$phablet: 600px;
$sm: 769px;
$md: 992px;
$lg: 1160px;
$xl: 1260px;

// Z INDEXS - USE WITH CAUTION
$indexHeaderMenu: 4;
$indexHide: -1;

$mobile-page-margin: 18px;

@function withalpha($color, $alpha) {
  @return rgba($color, $alpha);
}
