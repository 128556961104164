.SidebarNavItem {
  text-decoration: none;
  color: $font-primary-grey;
  font-size: 18px;
  display: flex;
  align-items: center;
  height: 55px;
  padding-left: 30px;
  width: 300px;
  max-width: 100%;

  &:hover {
    @include LatoBold();

    background-color: $greyBlue;
    box-shadow: inset 0 0 3px 1px rgba(15, 34, 69, 0.15);
  }

  &--isActive {
    @include LatoBold();

    background-color: $greyBlue;
  }

  &--working {
    cursor: wait;
    opacity: 0.1;
    color: rgba($font-primary-grey, 0.2);
  }

  &__span {
    display: block;
    padding-left: $spacing-medium;
    font-size: $font-size-default;
  }
}
