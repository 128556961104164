.CheckboxInput {
  flex: 1 1 50%;
  margin-bottom: 20px;
  align-items: center;
  align-content: center;
  display: flex;

  &__tickbox {
    display: flex;
    height: 25px;
    width: 25px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    min-height: 25px;
    min-width: 25px;
    margin-top: 2px;
    border-style: solid;
    border-width: 1px;
    border-color: inherit;
    fill: inherit;

    &__tick {
      fill: inherit;
      justify-self: center;
      display: none;
    }
  }

  & p {
    margin-left: 10px;
  }

  & input {
    display: none;
    fill: inherit;

    &:checked {
      & + .CheckboxInput__tickbox svg {
        display: block;
        fill: inherit;
      }
    }
  }
}
