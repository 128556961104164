.LoginForm {
  width: 100%;
  display: flex;
  flex-direction: column;

  & form {
    width: 100%;

    & .Form__inner .TextInput .TextInput__input {
      text-align: center;

      @include breakpoint($sm) {
        text-align: left;
      }

      &::placeholder {
        text-align: center;
        text-transform: uppercase;
      }
    }
  }

  &__form {
    &__rememberMe,
    &__forgotPassword {
      display: flex;
      align-items: center;

      & div {
        margin: 0;
      }
    }

    &__forgotPassword {
      justify-content: flex-end;
    }
  }

  &__container {
    width: 100%;
    height: 100%;
  }

  &__loader {
    background-color: transparent;
    width: 100%;
    height: 100%;
    min-height: 300px;

    &::after {
      border-color: $font-secondary-grey;
      width: 30px;
      height: 30px;
    }
  }

  @include breakpoint($sm) {
    max-width: unset;
    display: block;
    width: unset;
  }

  & .Label {
    display: none;

    @include breakpoint($sm) {
      display: block;
    }
  }
}
