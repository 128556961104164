.BrandBanner {
  display: flex;
  flex-direction: column;
  height: unset;
  margin: 0 30px;
  background-color: $white;

  @include breakpoint($sm) {
    flex-direction: row;
    margin: 30px 50px 0 0;
    height: 500px;
  }

  &__image {
    flex: 0.4 200px;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 100%;

      @include breakpoint($sm) {
        width: auto;
      }
    }
  }

  &__details {
    flex: 0.6;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $spacing-default;
    padding: 20px;

    @include breakpoint($sm) {
      padding: 60px;
    }

    &__title {
      font-size: 33px;
    }

    &__scrollContainer {
      position: relative;

      &__desc {
        margin-top: 10px;
        max-height: 210px;
        overflow-y: auto;
        padding-bottom: 10px;
      }

      &:after {
        background: linear-gradient(0deg, #fff 0%, rgba(255, 255, 255, 0) 100%) repeat scroll 0 0 rgba(0, 0, 0, 0);
        bottom: -1px;
        content: "";
        display: block;
        height: 25px;
        left: 0;
        right: 0;
        pointer-events: none;
        position: absolute;
        width: 100%;

        @include IEFix() {
          content: initial;
        }
      }
    }

    &__brands {
      text-transform: uppercase;
    }

    &__buttons {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      min-height: 100px;
      margin-top: 20px;

      @include breakpoint($sm) {
        justify-content: space-between;
      }

      & > button {
        max-width: 300px;
        min-width: 200px;

        @include breakpoint($sm) {
          max-width: unset;
        }

        &:first-of-type {
          flex: 0.55;
          margin-right: 15px;
        }

        &:last-of-type {
          flex: 0.45;
        }
      }
    }
  }
}
