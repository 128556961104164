.EditProfileForm {
  padding-bottom: 50px;

  &__section {
    padding-bottom: 10px;
    margin: 50px 0;
    border-bottom: solid thin;

    & input {
      background-color: $white;
    }
  }

  &__change-password {
    margin-left: 0;
    width: 100%;

    @include breakpoint($sm) {
      margin-left: 20px;
      width: calc(100% - 20px);
    }
  }

  &__submitholder {
    display: flex;
    justify-content: center;
  }
}
