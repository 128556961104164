.Search {
  &__container {
    display:flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    border-radius: 20px;
    border: 1px solid black;
    transition: all 0.2s;

    &--active {
      opacity: 1;
    }

    &--not-active {
      opacity: 0.3;
      border-color: lightgrey;
    }
  }
}
