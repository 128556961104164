.Filter {
  position: relative;

  &__button {
    border-radius: 30px;
    border-width: 1px;
    border-style: solid;
    height: 60px;
    padding: 0 28px;
    font-size: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    text-transform: capitalize;
    cursor: pointer;
    color: $primary-blue;
  }

  &__dropdown {
    width: 284px;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
    background-color: $white;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 10;
  }

  &__cta {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;

    button {
      font-size: 18px;
      font-weight: 600;
      padding: 0;
    }
  }

  .CheckboxInput {
    &__tickbox {
      background-color: $white !important;
      border: 1px solid $black;

      &--active {
        background-color: $black !important;
      }
    }

    &__label {
      margin-left: 20px;
      font-weight: 700;
    }
  }
}
