.SocialFeedItem {
  @include grid();
  @include grid-template(100%, 100%);

  padding: 0;
  justify-content: center;
  align-items: center;
  height: calc(50vw - 45px);
  width: calc(50vw - 45px);
  color: $white;
  position: relative;
  margin: 0 5px 5px 0;
  overflow: hidden;

  @include breakpoint($sm) {
    height: calc(33vw - 130px);
    width: calc(33vw - 130px);
  }

  &__image {
    @include grid-placement(1, 1);

    min-width: 100%;
    height: 100%;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & img {
      width: auto;
      height: 100%;
    }
  }

  &__details {
    @include grid-placement(1, 1);

    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    height: calc(100% - 10px);
    width: calc(100% - 10px);
    flex: 1;
    transform: translateY(8%);
    opacity: 0;
    transition: all 0.2s ease-in-out;
    border: none;
    margin: 5px;

    & > p {
      transition: all 0.2s ease-in-out;
      transform: translateY(30%);
      opacity: 0;
      overflow: hidden;
      max-height: 130px;

      @include breakpoint($sm) {
        max-height: unset;
      }
    }
  }

  &:hover {
    .SocialFeedItem__details {
      transition: all 0.3s ease-in-out;
      transform: translateY(0);
      border: 1px solid $white;
      opacity: 1;

      & p {
        transition: all 0.5s 0.2s ease-in-out;
        transform: translateY(0);
        opacity: 1;
      }
    }

    .SocialFeedItem__image {
      opacity: 0.4;
    }
  }
}
