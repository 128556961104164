@mixin dropShadowForm {
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
}

@mixin standardFormStyle {
  @include Lato();

  border-radius: 30px;
  border-width: 1px;
  border-style: solid;
  height: 60px;
  width: 100%;
  padding: 0 28px;
  font-size: $font-size-default;
  transition: 0.2s ease border-color;
  border-color: $form-input-outline;

  &:focus {
    transition: 0.2s ease border-color;
  }
}

.FormElement {
  outline: none;
  margin-bottom: 25px;

  // General text inputs
  input[type="email"],
  input[type="password"],
  input[type="text"],
  input[type="number"] {
    @include standardFormStyle();

    &.FormElement--dropdown {
      font-family: inherit;
      border-width: 0;
      padding: 0;
      width: calc(100% - 20px);
    }
  }

  // General text inputs
  textarea[type="email"],
  textarea[type="password"],
  textarea[type="text"],
  textarea[type="number"] {
    @include standardFormStyle();

    min-height: 200px;
    height: auto;
    padding: 22px 30px;
  }

  &--needHelp {
    margin-bottom: 15px;
  }

  &__dropdown {
    @include standardFormStyle();

    margin-bottom: 25px;

    & .react-date-picker {
      &__wrapper {
        border: none;
        width: 100%;
      }
    }

    &--white {
      background: $white;
    }
  }
}

.SelectInput {
  user-select: none;

  &:hover {
    cursor: pointer;
  }

  &__wrapper {
    display: flex;
    position: relative;
    align-items: center;
    transition: all 0s;
    background-color: inherit;
  }

  &__background {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &--open {
    .SelectInput__dropdown__body {
      display: block;
    }

    .SelectInput__selected--input {
      z-index: 9;
    }
  }

  &__selected {
    background-color: inherit;

    &--input {
      background-color: inherit;
      border-radius: 0;
      border-width: 0;
      border-style: none;
      height: auto;
      width: auto;
      padding: auto;
      font-size: inherit;
      transition: none;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;

      &::-webkit-input-placeholder {
        color: black;
      }

      &:focus::-webkit-input-placeholder {
        color: grey;
      }

      &:disabled {
        opacity: 1;
        color: inherit;
      }
    }
  }

  &__arrow {
    transform: rotate(270deg);
    max-width: 10px;
    transition: transform 0.1s, fill 0.1s;
    position: absolute;
    right: 30px;
    top: calc(50% - 8px);
    padding: 0;

    & > svg {
      transition: transform 0.2s;
    }
  }

  &__dropdown {
    @include standardFormStyle();

    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    outline: none;
    border-color: inherit;
    background-color: inherit;

    &__body {
      @include dropShadowForm();

      position: absolute;
      top: calc(100% + 5px);
      left: 0;
      max-width: 100%;
      width: calc(100% + 50px);
      border-radius: 10px;
      overflow: hidden;
      background-color: $white;
      z-index: 9;
      max-height: 50vh;
      overflow-y: auto;
      display: none;

      &__item {
        padding: 20px 30px;
        transition: background-color 0.1s;
        overflow: hidden;
        text-overflow: ellipsis;
        z-index: 9;

        &:hover {
          background: black;
          color: $white;
          cursor: pointer;
        }
      }
    }
  }

  &--white {
    .SelectInput {
      &__dropdowm {
        background: $white;
      }
    }
  }

  &--short {
    max-width: 250px;
  }

  &--long {
    min-width: 300px;
  }

  &--no-margin {
    margin: 0;
  }

  &--small {
    .SelectInput {
      &__dropdown {
        height: 45px;
        font-size: $font-size-default;

        &__body__item {
          padding: 15px 30px;

          &--child {
            padding-left: 45px;
            display: flex;
            align-items: center;

            &::before {
              content: "";
              display: block;
              border-width: 1px;
              border-style: solid;
              height: 0;
              width: 8px;
              margin: 3px 8px 0 0;
            }
          }
        }
      }

      &__arrow {
        max-width: 8px;
      }
    }
  }
}

#dropdown-search {
  border-radius: 0;
  border-width: 0;
  border-style: none;
  height: auto;
  padding: 0;
  transition: none;
  background-color: transparent;
  width: calc(100% - 20px);

  @include breakpoint($sm) {
    font-size: inherit;
  }
}

/*
 * FormElement is abstract and for its children to leverage
 * specificity they should be imported after
 */
@import "./form";
@import "./text-input";
@import "./label";
@import "./helper-text";
@import "./reactCalendar";
@import "./file-uploads";
@import "./checkRadioInput";
@import "./checkRadioSelection";
