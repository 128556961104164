.ContactBanner {
  display: flex;
  padding: 0;
  color: $white;
  width: 100%;
  flex-wrap: wrap;

  @include breakpoint($phablet) {
    width: calc(100% - 60px);
  }

  @include breakpoint($sm) {
    width: calc(100% - 100px);
  }

  &__image {
    min-height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    flex: 1 1 200px;
    min-width: 380px;
    max-width: unset;

    @include breakpoint($lg) {
      max-width: 400px;
    }

    & img {
      width: 100%;
      height: auto;
    }
  }

  &__details {
    flex: 1 1 309px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 30px;

    @include breakpoint($phablet) {
      min-width: 300px;
    }

    &__contactText {
      text-transform: uppercase;
      padding-bottom: 20px;
    }

    &__title {
      color: rgba(255, 255, 255, 0.5);
      padding-bottom: 20px;
    }

    &__phone,
    &__email {
      display: flex;
      align-items: center;
      fill: $white;
      padding: 10px 0;

      @include LatoBold();

      & p {
        line-height: 15px;
        padding-left: 10px;
      }
    }
  }
}
