.DocumentSection {
  padding: 0 0 0 $mobile-page-margin;

  @include breakpoint($md) {
    padding: 0 50px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 50px;
    padding: 0;

    @include breakpoint($sm) {
      align-items: center;
      flex-direction: row;
      padding-right: $mobile-page-margin;
    }

    & .RoundedButton {
      display: none;

      @include breakpoint($sm) {
        flex-direction: row;
        display: block;
      }
    }
  }

  &__itemWrap {
    display: flex;
  }
}
