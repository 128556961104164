.FileAccordion {
  @include grid();
  @include grid-template(auto, 1fr auto);

  padding: $spacing-small 0;

  &__loader {
    height: 200px;
    display: flex;
    justify-content: center;

    & .Loader {
      max-width: 200px;
    }
  }

  &__header {
    @include grid-placement(1, 1);
    @include grid();
    @include grid-template(100%, auto auto 1fr);

    @include breakpoint($sm) {
      @include grid-template(100%, auto 1fr auto);
    }

    align-items: center;
    color: $primary-blue;
    padding: $spacing-small 0;

    &__title {
      display: flex;
      text-transform: uppercase;
      align-items: center;
      letter-spacing: 2px;

      @include grid-placement(1, 1);

      @include breakpoint($sm) {
        @include grid-placement(1, 1);
      }
    }

    &__seperator {
      display: block;
      flex: 1;
      height: 2px;
      margin-left: $spacing-default;
      margin-top: 2px;

      @include IEFix() {
        margin: auto 10px;
      }

      @include grid-placement(1, 3);

      @include breakpoint($sm) {
        @include grid-placement(1, 2);
      }
    }

    &__button {
      @include grid-placement(1, 2);

      @include breakpoint($sm) {
        @include grid-placement(1, 3);
      }

      transform: scaleY(-1);
      transition: all 0.3s ease-out;
    }
  }

  &__children {
    @include grid-placement(2, 1);

    height: 0;
    width: 100%;
    opacity: 0;
    overflow: hidden;
    will-change: height;
    padding-top: $spacing-small;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-left: 0;

    @include breakpoint($sm) {
      justify-content: flex-start;
    }

    & > div {
      width: 100%;
    }
  }

  &--open {
    .FileAccordion__children {
      height: auto;
      opacity: 1;
      transition: all 0.6s ease-out;
    }

    .FileAccordion__header__button {
      transform: scaleY(1);
    }
  }
}
