@import "./needHelpBanner";
@import "./needHelpAccordion";
@import "./quickGuidePopup";
@import "./quickGuideOverlay";
@import "./quickGuideCTA";
@import "./helpSearchBar";

.NeedHelp {
  &__loader {
    display: flex;
    justify-content: center;

    & .Loader {
      height: 100px;
      width: 100px;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 70px 10px;
    justify-content: space-between;
    height: 370px;
    color: $white;
    font-size: 18px;
    margin-bottom: 0;
    text-align: center;

    @include breakpoint($sm) {
      padding: 70px;
      text-align: left;
      margin-bottom: 90px;
    }

    &__title {
      text-transform: uppercase;
    }
  }

  &__content {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    @include breakpoint($sm) {
      padding: 0 90px 90px;
    }
  }

  &__search {
    width: 100%;
    text-align: center;
    position: relative;
    max-width: 500px;

    @include breakpoint($sm) {
      width: 70%;
    }

    svg {
      position: absolute;
      top: 22px;
      right: 28px;
    }
  }
}
