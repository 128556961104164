@import "./headerSearch";
@import "./headerProfile";
@import "./headerNotifications";
@import "./NotificationItem";

.MainHeader {
  @include grid();
  @include grid-template(auto, auto auto 1fr auto auto);

  @include breakpoint($sm) {
    @include grid-template(auto, auto auto auto 1fr auto auto);
  }

  justify-content: center;
  align-items: center;
  height: 70px;
  padding: 0 35px;
  border-bottom: 1px solid #979797;

  &--appForm {
    @include grid-template(auto, auto auto 1fr auto auto);

    @include breakpoint($sm) {
      @include grid-template(auto, auto auto auto 1fr auto auto);
    }
  }

  &__image {
    margin-right: 30px;
    display: none;
    justify-content: center;
    align-items: center;

    @include grid-placement(1,1);

    @include breakpoint($sm) {
      display: flex;
    }

    &--appForm {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0;
      height: 50px;

      @include breakpoint($sm) {
        display: none;
      }
    }

    & img {
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 50px;
    }
  }

  &__burgerMenu {
    display: block;
    margin-right: 30px;
    height: 30px;

    @include grid-placement(1,1);

    @include breakpoint($sm) {
      display: none;
    }

    &__icon {
      position: relative;
      width: 22px;
    }

    &__icon,
    &__icon::before,
    &__icon::after {
      content: "";
      display: block;
      height: 3px;
      background-color: $white;
    }

    &__icon::before,
    &__icon::after {
      position: absolute;
      width: 29px;
    }

    &__icon::before {
      top: -9px;
    }

    &__icon::after {
      bottom: -9px;
    }
  }

  & .BrandDropdown {
    display: none;
    margin-right: 30px;
    justify-content: center;
    align-items: center;

    @include grid-placement(1,2);

    @include breakpoint($sm) {
      display: flex;
    }

    & .SelectInput__wrapper .SelectInput__dropdown .SelectInput__selected--input {
      margin-right: 10px;
      background-color: transparent;
      border-radius: 0;
    }

    &__span {
      color: transparent;
      background-color: transparent;
      position: absolute;
      top: -40px;
      font-size: 17px;
      max-width: 90vw;

      @include Lato();
    }
  }

  & .HeaderNotifications,
  & .HeaderProfile {
    padding: 0 10px;
    justify-content: flex-end;

    @include breakpoint($sm) {
      padding: 0 12px;
    }
  }

  & .HeaderProfile {
    padding-right: 0;

    @include grid-placement(1,5);

    @include breakpoint($sm) {
      @include grid-placement(1,6);
    }
  }

  & .HeaderNotifications {
    @include grid-placement(1,4);

    @include breakpoint($sm) {
      @include grid-placement(1,5);
    }
  }
}
