html,
body {
  @include Lato();

  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased;
  font-size: $font-size-default;
  color: $font-primary-grey;
  background-color: $grey;
  overflow: hidden;
  text-rendering: optimizeLegibility;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
  background-color: #f5f5f5;
  overflow: hidden;
}

p {
  font-size: $font-size-default;
  line-height: 1.67;
}

strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

* {
  box-sizing: border-box;
  transition: background-color 0.3s ease-in;
}

h1,
h2,
h3,
h4,
h5 {
  @include PlayfairBold();

  margin: 0;
  padding: 0;
}

h1 {
  font-size: $font-size-large;
  line-height: 1.15;
}

h2 {
  font-size: $font-size-medium;
}

.lightHeaderSansSerif {
  @include Lato();

  text-transform: uppercase;
  font-size: $font-size-title-small;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: $letter-spacing-heading;
  margin-bottom: 10px;

  &--light {
    font-weight: 100;
  }
}

.headerSerif {
  @include PlayfairBold();

  font-size: $font-size-title-large;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.7;
  letter-spacing: normal;
  margin-bottom: 10px;
}

a,
button,
svg {
  touch-action: manipulation;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;

  &:disabled {
    cursor: default;

    * {
      cursor: default;
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}

textarea {
  resize: none;
}

a,
input,
button,
textarea {
  outline: none;
}

.fontsize {
  &--small {
    font-size: $font-size-small;
  }
}

%noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.Modal {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  outline: none;
}

.ModalOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 50;
}

.lineDivide {
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: solid thin rgba($font-secondary-grey, 0.5);
}

.alignSelfCenter {
  align-self: center;
}

.alignCenter {
  text-align: center;
}

.alignCenterMobile {
  text-align: center;

  @include breakpoint($sm) {
    text-align: inherit;
  }
}

.alignRight {
  text-align: right;
}

.flex {
  display: flex;
  flex-wrap: wrap;

  @include breakpoint($sm) {
    flex-wrap: nowrap;
  }

  &--space-between {
    justify-content: space-between;
  }

  &--center {
    align-items: center;
  }

  &--80 {
    width: 80%;
  }

  &--70 {
    width: 100%;

    @include breakpoint($sm) {
      width: 70%;
    }
  }

  &--60 {
    width: 100%;

    @include breakpoint($sm) {
      width: 60%;
    }
  }

  &--40 {
    width: 100%;

    @include breakpoint($sm) {
      width: 40%;
    }
  }

  &--30 {
    width: 100%;

    @include breakpoint($sm) {
      width: 30%;
    }
  }

  &--20 {
    width: 20%;
  }
}
