.LoginSlider {
  width: 100%;
  height: 100%;
  max-height: 100vh;
  position: relative;

  &__slide {
    &__image {
      display: flex;
      justify-content: center;
      align-items: center;

      & img {
        width: auto;
        min-height: 100%;
      }
    }
  }

  &__info {
    position: absolute;
    bottom: 10px;
    height: unset;
    max-height: 95%;
    left: 10%;
    right: 10%;
    border: solid 1px #979797;
    background-color: #fff;
    opacity: 0.8;
    padding: 31px 43px;
    z-index: 2;
    display: flex;
    flex-direction: column;

    @include breakpoint($sm) {
      height: 260px;
    }

    & > h1 {
      margin-bottom: 20px;
      white-space: pre-wrap;
    }

    & > p {
      flex: 1;
      margin-bottom: 10px;
      overflow: hidden;
    }

    &__pagination {
      align-self: flex-end;

      &__bullet {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        padding: 0;
        border: 1px solid #2b2c2f;
        background-color: transparent;
        margin-right: 10px;

        &--active {
          background-color: #2b2c2f;
          border: 1px solid transparent;
        }
      }
    }
  }

  &__slider {
    & nav.awssld__bullets {
      bottom: 20px;
      z-index: 3;
      left: 10%;
      right: 10%;
      width: 80%;
      display: flex;
      justify-content: flex-end;
      padding-right: 7%;
      padding-bottom: 15px;

      &.awssld__bullets {

        & > button {
          border: #2b2c2f 1px solid;
          background: transparent;
          height: 12px;
          width: 12px;

          &.awssld__bullets--active {
            transform: none;
            background-color: #2b2c2f;
          }
        }
      }
    }

    &__image {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      & ~ .awssld__timer {
        position: absolute;
      }

      & img {
        min-height: 100%;
        width: auto;
      }
    }
  }

  
}
