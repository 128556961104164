.Clock {
  margin-top: 20px;
  text-align: center;
  border: 1px solid $font-primary-grey;
  padding: $spacing-small 0;
  width: 200px;
  border-radius: 20px;
  white-space: nowrap;
  font-size: $font-size-default;
}
