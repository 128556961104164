.FilePreview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  flex-flow: column;
  color: #fff;
  z-index: 10;

  &__header {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 10px;
    height: 5vh;

    &__filename {
      font-weight: bold;
    }
  }

  &__body {
    display: flex;
    flex-flow: row;
    justify-content: space-between;
    padding: 25px 10px;
    height: 95vh;

    &__nav {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 2vw;
      max-width: 2vw;
    }
    &__content {
      display: flex;
      flex-flow: column;
      justify-content: center;
      align-items: center;
      width: 95vw;
      max-width: 95vw;

      &__file {
        max-height: 85vh;
      }

      & img,
      & video,
      &__file {
        max-width: 95%;
      }
    }
  }

  &__icons {
    cursor: pointer;
    font-weight: bold;
    font-size: 25px;
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__openDocument {
    padding: 0;
    font-size: 14px;
    color: blue;
  }
}
