.HeaderProfile {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 70px;
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__menu {
    position: absolute;
    z-index: $indexHeaderMenu;
    top: calc(100% + 23px);
    left: 50%;
    transform: translate(-60%);
    flex-direction: column;
    justify-content: space-around;
    background: $white;
    width: 147px;
    border-radius: 10px;
    box-shadow: 0 9px 10px -3px rgba(0, 0, 0, 0.4);
    display: none;
    opacity: 0;
    transition: all 0.3s ease-in-out;
    padding: 10px 0;

    & > div {
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    @include breakpoint($sm) {
      transform: translate(-55%);
    }

    @include breakpoint($md) {
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        /* IE10+ CSS styles go here */
        transform: translateX(-60%);
        padding-top: 20px;
      }
    }
    &__button {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: $font-size-default;
      width: 100%;
      @include LatoBold();
    }
  }
  &__image {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    margin-right: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    overflow: hidden;
    @include breakpoint($md) {
      margin-right: 10px;
      height: 40px;
      width: 40px;
    }
    &--initials {
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      & h1 {
        text-align: center;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 26px;
        padding: 10px;
        margin-bottom: 4px;
        color: $white;
        @include PlayfairBold();
      }
    }
  }
  &__arrow {
    display: none;
    @include breakpoint($md) {
      display: block;
    }
  }
  &::before {
    display: none;
    box-sizing: border-box;
    content: "";
    height: 10px;
    width: 10px;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-bottom: 18px solid $white;
    position: absolute;
    bottom: -25px;
    z-index: 5;
    left: 50%;
    transform: translate(calc(-50% + 5px));

    @include breakpoint($sm) {
      transform: translate(calc(-50% + 12px));
    }

    @include breakpoint($md) {
      right: 37px;
      left: unset;
      transform: unset;
    }
  }
  &--active {
    transition: all 0.3s ease-in-out;
    display: flex;
    opacity: 1;
    &::before {
      transition: all 0.3s 2s ease-in-out;
      display: initial;
      opacity: 1;
    }
    .HeaderProfile__menu {
      display: flex;
      opacity: 1;
    }
  }
}
