.Label {
  padding-bottom: 12px;

  &__label {
    color: $font-secondary-grey;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-size: 15px;
  }
}
