.ArticleCard {
  text-align: left;
  background-color: $white;
  width: 314px;
  height: 100%;
  padding: $spacing-small;
  transform: scale(1);
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border-bottom-width: 5px;
  border-bottom-style: solid;
  margin-right: 0;
  margin-bottom: 0;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  overflow: hidden;

  @include breakpoint($sm) {
    margin-bottom: 30px;
  }

  &:hover {
    transform: scale(1);

    @include breakpoint($sm) {
      transform: scale(1.02);
      box-shadow: 2px 5px 20px rgba(0, 0, 0, 0.1);
    }
  }

  &__image {
    height: 150px;
    min-height: 150px;
    max-height: 150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    img {
      min-height: 150px;
      height: auto;
      width: 100%;
    }
  }

  &__body {
    padding: $spacing-medium;
    height: 100%;

    &__date {
      font-size: $font-size-small;
    }

    &__info {
      font-size: 16px;
      line-height: 25px;
      word-break: break-word;

      @include Lato();
    }

    &__title {
      padding: $spacing-small 0 $spacing-medium 0;
      margin: 0;
      font-size: 28px;
      line-height: 1;
    }
  }
}
