@import "./Clock/clock";

$desktopHeaderHeight: 100px;

.SidebarBackground {
  position: absolute;
  top: 70px;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  display: none;

  &--show {
    opacity: 1;
    display: initial;
  }

  @include breakpoint($sm) {
    display: none;
  }
}

.Sidebar {
  transform: translateX(-100%);
  padding: 16px 0;
  background: $white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 260px;
  transition: transform 0.3s ease-in-out, z-index 1s;
  overflow-x: auto;
  z-index: 0;

  @include breakpoint($sm) {
    transform: translate(0);
    padding: 30px 0;
    display: flex;
  }

  &--open {
    transform: translateX(0);
    min-width: 260px;
    display: flex;
    z-index: 2;
    transition: transform 0.3s 0.1s ease-in-out, z-index 0.5s;
  }

  &__nav {
    align-self: flex-start;
    min-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.SidebarCallToAction {
  margin: 0 30px 20px;
  width: calc(100% - 80px);
  align-self: center;

  &__button {
    width: 100%;
  }
}
