$fontsPath: "./fonts/";

$bold: 700;
$regular: 300;

@font-face {
  font-display: swap;
  font-family: "Lato";
  src: url($fontsPath+"Lato/"+"Lato-Regular.eot");
  src: url($fontsPath+"Lato/"+"Lato-Regular.eot?#iefix")
      format("embedded-opentype"),
    url($fontsPath+"Lato/"+"Lato-Regular.woff2") format("woff2"),
    url($fontsPath+"Lato/"+"Lato-Regular.woff") format("woff"),
    url($fontsPath+"Lato/"+"Lato-Regular.ttf") format("truetype");
  font-weight: $regular;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "LatoBold";
  src: url($fontsPath+"Lato/"+"Lato-Bold.eot");
  src: url($fontsPath+"Lato/"+"Lato-Bold.eot?#iefix")
      format("embedded-opentype"),
    url($fontsPath+"Lato/"+"Lato-Bold.woff2") format("woff2"),
    url($fontsPath+"Lato/"+"Lato-Bold.woff") format("woff"),
    url($fontsPath+"Lato/"+"Lato-Bold.ttf") format("truetype");
  font-weight: $bold;
  font-style: normal;
}

@mixin Lato() {
  font-family: "Lato";
}

@mixin LatoBold() {
  font-family: "LatoBold";
}

@font-face {
  font-display: swap;
  font-family: "Playfair";
  src: url($fontsPath+"Playfair/"+"PlayfairDisplay-Regular.eot");
  src: url($fontsPath+"Playfair/"+"PlayfairDisplay-Regular.eot?#iefix")
      format("embedded-opentype"),
    url($fontsPath+"Playfair/"+"PlayfairDisplay-Regular.woff2") format("woff2"),
    url($fontsPath+"Playfair/"+"PlayfairDisplay-Regular.woff") format("woff"),
    url($fontsPath+"Playfair/"+"PlayfairDisplay-Regular.ttf") format("truetype");
  font-weight: $regular;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: "PlayfairBold";
  src: url($fontsPath+"Playfair/"+"PlayfairDisplay-Bold.eot");
  src: url($fontsPath+"Playfair/"+"PlayfairDisplay-Bold.eot?#iefix")
      format("embedded-opentype"),
    url($fontsPath+"Playfair/"+"PlayfairDisplay-Bold.woff2") format("woff2"),
    url($fontsPath+"Playfair/"+"PlayfairDisplay-Bold.woff") format("woff"),
    url($fontsPath+"Playfair/"+"PlayfairDisplay-Bold.ttf") format("truetype");
  font-weight: $bold;
  font-style: normal;
}

@mixin Playfair() {
  font-family: "Playfair";
}

@mixin PlayfairBold() {
  font-family: "PlayfairBold";
}
