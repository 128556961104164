.DocumentItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 172px;
  color: $primary-blue;
  margin-right: 0;
  margin-bottom: 30px;
  margin-top: 30px;

  @include breakpoint($sm) {
    margin-right: 100px;
    margin-bottom: 0;
  }

  &:last-of-type {
    margin-right: 0;
    margin-bottom: 0;
  }

  &__titleWrap {
    height: $spacing-xlarge;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &__title {
      width: 100%;
      text-align: center;
      font-size: $font-size-default;
      padding: $spacing-small 0;
      word-break: break-word;
      max-height: 64px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include IEFix() {
        text-overflow: ellipsis;
      }
    }
  }

  &__imageWrap {
    height: 244px;
    position: relative;
    overflow: hidden;

    @include grid();
    @include grid-template(244px, 172px);

    justify-content: center;
    align-items: center;

    &__fileExt p {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__image {
      width: 100%;
      height: 100%;
      display: block;
      position: relative;

      & img {
        display: block;
        position: relative;
        object-fit: contain;
      }

      @include grid-placement(1, 1);
    }

    &__overlay {
      @include grid-placement(1, 1);

      width: 100%;
      height: 100%;
      opacity: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      background-color: withalpha($primary-blue, 0.7);
      transition: all 0.4s 0.1s;
      will-change: opacity;
      padding: 20px;

      &__icon {
        opacity: 0;
        will-change: opacity;
        transition: all 0s;

        * {
          width: 25px;
        }

        @include IEFix() {
          opacity: 1;
          transition: none;
        }
      }

      &:hover {
        transition: all 0.3s;
        opacity: 1;

        .DocumentItem__imageWrap__overlay__icon {
          transition: all 0.3s 0.15s ease-in;
          opacity: 1;

          &:hover {
            transition: all 0s;
          }
        }
      }
    }
  }

  &__download {
    height: 50px;
    width: 160px;
  }
}
