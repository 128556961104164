.FileViewDocument {
  @include grid();
  @include grid-template(130px 75px, 1fr);

  justify-items: center;
  align-items: center;

  @include breakpoint($phablet) {
    @include grid-template(190px 75px, 240px);
  }

  &__nameWrap {
    display: flex;
    align-self: start;
    align-items: center;
    justify-content: center;
    width: 100%;

    @include grid-placement(2,1);

    &__name {
      max-width: 70%;
      width: 100%;
      white-space: normal;
      text-align: center;
      align-self: start;
      word-break: break-word;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;

      @include IEFix() {
        text-overflow: ellipsis;
      }

      @include breakpoint($phablet) {
        max-width: 90%;
        width: unset;
        margin: 0;
      }
    }

    &__dots {
      display: flex;

      @include grid-placement(2, 1);

      @include breakpoint($phablet) {
        display: none;
      }
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    border-radius: 5px;
    height: 100px;
    width: 100px;
    overflow: hidden;
    border: 1px solid $greyBlue;

    @include IEFix() {
      margin: 0 auto;
    }

    @include grid-placement(1,1);

    @include breakpoint($phablet) {
      height: 170px;
      width: 200px;
    }

    &__previewWrap {
      width: 100%;
      flex: 1;
      overflow: hidden;
      position: relative;

      &__preview {
        cursor: pointer;
        min-width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        & img {
          width: 100%;
          height: auto;
        }
      }

      &__fileExt {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;

        & p {
          text-transform: uppercase;
          padding: 3px 4px 2px;
          border-width: 2px;
          border-style: solid;
          border-radius: 3px;
          border-color: inherit;
          font-size: 14px;
        }

        @include LatoBold();
      }
    }

    &__menu {
      display: flex;
      padding: $spacing-small;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &__buttons {
        display: flex;
        align-items: center;

        &--download,
        &--share,
        &--dots {
          justify-content: center;
          align-items: center;
          display: none;

          @include breakpoint($phablet) {
            display: flex;
          }
        }

        &__icon {
          margin: $spacing-small 0;
          display: flex;
          justify-content: center;
          align-items: center;

          @include breakpoint($phablet) {
            margin: $spacing-small;
          }
        }
      }

      &__fileExt {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 3px 3px 2px;
        border-width: 2px;
        border-style: solid;
        border-radius: 3px;

        & p {
          font-size: $font-size-x-small;
          text-transform: uppercase;
        }

        @include LatoBold();
      }
    }
  }
}
