$popupWidth: 400px;

.QuickGuidePopup {
  cursor: auto;
  position: absolute;
  height: 100%;
  z-index: 3;

  &__container {
    position: relative;
    border-radius: 0;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
    background-color: #fff;
    margin-top: 0;
    height: 100%;
    width: 100vw;

    @include breakpoint($sm) {
      margin-top: 15px;
      height: auto;
      max-width: unset;
      border-radius: 5px;
      width: $popupWidth;
    }
  }

  &__arrow {
    position: absolute;
    top: 0;
    left: 0;
    width: $popupWidth - 100;
    height: $popupWidth - 100;
    pointer-events: none;
    display: none;
    z-index: 2;

    @include breakpoint($sm) {
      display: initial;
    }

    &::after {
      content: "";
      width: 20px;
      height: 20px;
      background: #fff;
      position: absolute;
      left: 0;
      right: 0;
      top: -10px;
      margin: auto;
      animation: bounceArrow 1s infinite;
    }

    &--side {
      &-top {
        left: 0;
        right: 0;
        margin: auto;
      }

      &-left {
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: rotate(-90deg);
      }

      &-right {
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        transform: rotate(-90deg);
      }

      &-bottom {
        right: 0;
        left: 0;
        bottom: -20px;
        margin: auto;
        transform: rotate(180deg);
      }
    }

    &--position {
      &-left {
        &::after {
          left: 0;
          right: auto;
        }
      }

      &-top {
        &::after {
          left: auto;
          right: 20px;
        }
      }

      &-bottom {
        &::after {
          left: 0;
          right: auto;
        }
      }

      &-right {
        &::after {
          left: auto;
          right: 0;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    height: 100%;

    @include breakpoint($sm) {
      height: auto;
    }

    &__image {
      margin: 20px 0;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
      padding: 0 30px 20px;

      &__button {
        flex: 0.5;

        &--skip {
          margin-right: 5px;
        }

        &--next {
          margin-left: 5px;
        }
      }
    }

    &--spacing {
      margin-bottom: 20px;
      padding: 30px;
      border-bottom-style: solid;
      border-bottom-width: thin;
      flex: 1;
      overflow: auto;
      height: 235px;

      @include breakpoint($sm) {
        flex: auto;
      }
    }

    &__heading {
      font-size: 30px;
    }

    &__type {
      font-size: $font-size-default;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.67;
      letter-spacing: normal;
      margin-top: 20px;
    }
  }
}

@keyframes bounceArrow {
  0% {
    transform: translateY(0) rotate(45deg);
  }

  50% {
    transform: translateY(5px) rotate(45deg);
  }

  100% {
    transform: translateY(0) rotate(45deg);
  }
}
