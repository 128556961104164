.RoundedButton {
  @include LatoBold();

  border-radius: 30px;
  font-size: $font-size-default;
  font-weight: 100;
  color: $white;
  padding: $spacing-small $spacing-medium;
  border: 1px solid;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  height: 45px;
  text-align: center;
  display: inline-block;

  &--small {
    padding: 0;
    border-radius: 30px;
  }

  &--medium {
    padding: 5px 35px;
    border-radius: 35px;
  }

  &--large {
    padding: 10px 45px;
    border-radius: 45px;
  }

  &--transparent {
    background: transparent;
    border-color: transparent;
  }

  & p {
    white-space: nowrap;
    display: inline-block;
  }

  &__icon {
    margin-right: 10px;
    display: inline-block;
  }

  &__loader {
    height: 45px;
    display: flex;
    justify-content: center;

    & .Loader {
      width: 45px;
      height: 45px;
    }
  }
}
