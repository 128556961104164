.Pagination {
  &__button {
    font-size: 16px;
    width: 60px;
    height: 60px;
    margin-right: 5px;
    border-radius: 50%;
    transition: border 0.3s;
    color: $font-calendar-grey;

    &.active,
    &:hover {
      border-width: 1px;
      border-style: solid;
      color: $primary-blue;
    }
  }
}
