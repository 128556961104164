.DashboardLayout {
  @include grid-template(auto 1fr, 100%);

  @include breakpoint($sm) {
    @include grid();
    @include grid-template(auto 1fr, auto 1fr);
  }

  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  &__content {
    max-height: calc(100% - 70px);
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    @include breakpoint($sm) {
      @include grid-placement(2, 2);

      max-height: 100%;
    }
  }

  .Sidebar {
    position: absolute;
    bottom: 0;
    top: 70px;

    @include breakpoint($sm) {
      @include grid-placement(2, 1);

      position: initial;
      bottom: auto;
      top: auto;
    }
  }

  .MainHeader {
    @include breakpoint($sm) {
      @include grid-placement(1, 1, 1, 2);
    }
  }
}
