.FileView {
  @include grid();
  @include grid-template(205px, repeat(auto-fit, calc(50%)));

  justify-items: center;
  align-items: center;
  align-content: center;
  width: 100%;
  margin-left: 0;

  @include breakpoint($phablet) {
    @include grid-template(265px, repeat(auto-fit, 240px));

    justify-items: start;
    margin-left: -10px;
  }

  @include IEFix() {
    display: flex;
    flex-wrap: wrap;
  }
}
