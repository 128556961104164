@import "./components/newsSlider";
@import "./components/pageSelector";
@import "./components/pagination";
@import "./components/filter";
@import "./components/activeFilterList";

.News {
  padding: $spacing-large;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  -webkit-transform: translate3d(0, 0, 0);

  @include breakpoint($sm) {
    padding: 50px;
  }

  &__activeFilters {
    align-self: flex-start;
  }

  &__articles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px 0 0;

    @include breakpoint($sm) {
      padding: 30px 0 $spacing-small;
      width: calc(100% + 30px);
    }

    &__noItems {
      margin: 0 auto 40px;
    }

    .ArticleCard {
      width: 100%;
      margin: 0 0 30px;
      flex: 100% 0;

      @include breakpoint($sm) {
        max-width: 350px;
        min-width: 220px;
        height: auto;
        margin: 0 15px 30px;
      }

      @include breakpoint(830px) {
        flex: calc(50% - 30px) 0;
      }

      @include breakpoint(1080px) {
        flex: calc(33% - 30px) 0;
      }

      @include breakpoint(1330px) {
        flex: calc(25% - 30px) 0;
      }
    }
  }

  &__loader {
    height: 200px;
    display: flex;
    justify-content: center;

    .Loader {
      width: 200px;
    }
  }

  &Article {
    padding-bottom: 30px;

    &__loader {
      height: 100px;
      width: 100px;
      margin-bottom: 100px;
    }

    &__main {
      min-height: calc(100vh - 170px);
      padding: 30px 30px 20px;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: unset;

      @include breakpoint($sm) {
        margin: 50px;
        background-color: $white;
        padding: 50px 50px 20px;
      }

      &__toolbar {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include breakpoint($sm) {
          margin-bottom: 50px;
        }

        &__back,
        &__next {
          display: flex;
          align-items: center;

          &__icon {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 40px;
            width: 40px;
            border-style: solid;
            border-width: 1px;
            border-color: inherit;
            border-radius: 50%;
            padding-right: 2px;
          }
        }

        &__next {
          & p {
            margin-right: $spacing-small;
          }

          &__icon {
            transform: rotate(180deg);
          }
        }

        &__back {
          & p {
            margin-left: $spacing-small;
          }
        }
      }

      &__title {
        margin-bottom: 30px;
        width: 90%;

        @include breakpoint($sm) {
          width: 60%;
        }
      }

      &__image {
        width: 75%;
        max-height: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
      }

      &__date {
        margin: 0 10% 10px;
        text-transform: uppercase;
        width: 90%;

        @include breakpoint($sm) {
          width: 60%;
        }
      }

      &__article {
        width: 90%;

        @include breakpoint($sm) {
          width: 60%;
        }

        & a {
          word-break: break-word;

          @include LatoBold();
        }

        & > * {
          max-width: 100%;
          margin: 30px 10px;
          white-space: pre-line;

          @include breakpoint($sm) {
            margin: 30px;
          }

          & * {
            max-width: 100%;
          }

          &:first-child {
            margin: 0 0 30px;
          }

          & img {
            max-width: 100%;
            width: auto;
            height: auto;
            display: block;
            margin: auto;
          }
        }

        & > p:nth-of-type(2) {
          padding-left: 25px;
          border-width: 2px;
          border-left-style: solid;
          border-color: inherit;
        }
      }
    }
  }

  &__topBar {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin: 20px -22px 30px;
    width: 100%;

    @include breakpoint($sm) {
      margin: 30px 0;
      flex-direction: row;
      align-items: flex-end;
    }

    &__sort,
    &__filter {
      display: flex;
      flex-wrap: wrap;
      align-items: center;

      & .SortDropDown {
        margin-right: 50px;
      }

      & .Filter {
        margin-right: 20px;
      }

      &--mobile {
        margin: 20px 0 5px;
        width: calc(100% - 60px);

        & .SortDropDown,
        & .Filter {
          width: 100%;
          margin: 0;
        }

        & > div.Filter:nth-of-type(3) {
          margin-top: 10px;
        }
      }

      &__buttons {
        display: flex;

        &__background {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: none;

          &--active {
            display: block;
          }
        }
      }
    }

    &__label {
      font-size: 16px;
      text-transform: uppercase;
      margin: 8px 20px 8px 0;
      letter-spacing: 2.5px;
    }

    &__menu {
      background-color: $greyE7;
      display: flex;
      width: 100%;
      height: 70px;

      &__option {
        flex: 1;
        text-transform: uppercase;
        font-size: 16px;
        letter-spacing: 2.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $primary-blue;
        cursor: pointer;

        svg {
          transform: rotate(-90deg);
          width: 8px;
          margin-left: 9px;
          margin-top: 4px;
          fill: $primary-blue;
        }

        &:nth-of-type(2) {
          position: relative;

          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 21px;
            bottom: 21px;
            left: 0;
            width: 0;
            border-right: 1px solid $greyD8;
          }
        }
      }
    }

    &__fixedContainer {
      position: fixed;
      flex-direction: column;
      box-sizing: border-box;
      top: 55px;
      bottom: 0;
      right: 0;
      left: 0;
      z-index: 10;
      background: white;
      display: flex;
      // justify-content: center;
      align-items: center;
      overflow-y: scroll;

      .SortDropDown {
        &.SortDropDown {
          border-top: 30px solid transparent;
          margin-right: 0;
        }
      }

      .Filter {
        border-top: 30px solid transparent;
        flex-direction: column;

        &__button {
          width: 284px;
        }

        &__dropdown {
          margin-top: 30px;
          position: static;
        }

        &.Filter {
          margin-right: 0;
        }
      }
    }

    .SortDropDown {
      .FormElement {
        margin-bottom: 0;
      }
    }

    .PageSelector__toolbar__selector {
      margin-left: auto;
    }
  }

  &__typeSelector {
    width: 100%;
    height: 45px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    position: relative;

    &__input {
      display: none;

      &:checked + label.News__typeSelector__label {
        border-bottom-style: solid;
        border-bottom-width: 3px;
        transition: all 0.4s ease-in-out;
      }
    }

    &__label {
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: all 0.4s ease-in-out;
      font-size: 26px;
      border-bottom: solid 3px transparent;
      margin-right: 30px;
      opacity: 0.5;

      @include PlayfairBold();
    }
  }

  &__footer {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-bottom: 60px;

    @include breakpoint($sm) {
      justify-content: space-between;
    }
  }
}
