.NotificationsSettings {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__loader {
    height: 200px;
    display: flex;
    justify-content: center;
    margin-right: 50px;

    & .Loader {
      max-width: 200px;
    }
  }

  @include breakpoint($sm) {
    padding: 10%;
  }

  &__title {
    margin-bottom: 10px;
  }

  &__info {
    margin-bottom: 50px;
  }

  &__ticklistHeader {
    margin-bottom: 30px;
    text-transform: uppercase;
  }

  &__ticklist {
    display: flex;
    align-items: flex-start;
    flex-flow: column nowrap;

    @include breakpoint($sm) {
      flex-flow: row wrap;
    }

    &__seperator {
      width: 100%;
      height: 1px;
      margin: 20px 0 30px;
    }

    &__submit {
      align-self: center;
      width: 200px;
      height: 40px;
    }
  }
}
