.QuickGuideCTA {
  background: rgba(#7cb4c5, 0.1);
  padding: 60px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
  flex-flow: row wrap;

  @include breakpoint($sm) {
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__text {
    @include Lato();

    margin-top: 30px;
    font-size: $font-size-default;
    line-height: 1.8;
  }

  &__left {
    max-width: 450px;
    flex: 1 1 450px;
  }

  &__right {
    flex: 1 1 200px;
    align-self: flex-start;
    width: unset;
    text-align: left;
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;

    @include breakpoint($sm) {
      text-align: right;
      align-self: center;
      width: 30%;
      margin: 20px 30px 20px 0;
      justify-content: flex-end;
    }
  }
}
