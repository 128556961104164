.ContactItem {
  background-color: $white;
  color: $font-primary-grey;
  border-radius: 5px;
  height: initial;
  width: 250px;
  min-width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-top: 20px;
  overflow: visible;

  @include breakpoint($sm) {
    transform: scale(1);
    box-shadow: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
    transition: box-shadow 0.2s, transform 0.2s;

    &:hover {
      transform: scale(1.01);
      box-shadow: 0 5px 10px rgba(0, 0, 0, 0.05);
    }
  }

  &__image {
    border-radius: 50%;
    height: 90px;
    width: 90px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      height: auto;
    }

    &--initials {
      background-color: $white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-style: solid;
      border-width: 1px;

      & h1 {
        text-align: center;
        line-height: 0.9;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 35px;
        padding: 15px;

        @include LatoBold();
      }
    }
  }

  &__details {
    width: 100%;
    padding: 20px 32px;
    color: $black;

    &__firstname,
    &__lastname,
    &__job,
    &__phone {
      width: 100%;
      word-break: break-word;
    }

    &__firstname {
      margin-bottom: 5px;
      font-size: 14px;
      font-weight: 100;
    }

    &__lastname,
    &__firstname {
      text-transform: uppercase;
    }

    &__lastname {
      font-size: 24px;
      line-height: 0.9;
      margin-bottom: 20px;

      @include PlayfairBold();
    }

    &__job {
      margin-bottom: 20px;
    }
  }

  &__sendMessage {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px 32px;
    border-top: 1px solid $very-light-grey;

    &__button {
      border-radius: 50%;
      margin-right: 20px;
      height: 50px;
      width: 50px;
      border-width: 1px;
      border-style: solid;

      &:last-of-type {
        margin: 0;
      }
    }
  }
}
