.BrandItem {
  width: 150px;
  height: 100%;

  @include breakpoint($sm) {
    width: 230px;
  }

  &__image {
    transform: scale(0.9);
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
    transition: transform 0.5s;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    @include breakpoint($sm) {
      height: 196px;
    }

    & > img {
      height: auto;
      vertical-align: text-bottom;
    }

    &--selected {
      transform: scale(1);
      opacity: 1;
    }
  }
}
