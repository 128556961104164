@import "brandsSlider";
@import "brandItem";
@import "brandBanner";
@import "brandSwiperComponent";

.BrowseBrands {
  position: relative;
  padding-bottom: 50px;

  &__loader {
    height: 200px;
    display: flex;
    justify-content: center;

    & .Loader {
      max-width: 200px;
    }
  }

  &__header {
    max-width: 624px;
    padding: 50px 30px 20px;

    @include breakpoint($sm) {
      padding: 50px 50px 20px;
    }

    & h1 {
      margin-bottom: 10px;
    }
  }
}
