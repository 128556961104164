.PageSelector {
  display: flex;
  flex-flow: column;
  padding-top: 52px;
  margin-left: 50px;
  overflow: hidden;

  &__toolbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 50px;
    margin-bottom: 25px;

    &__selector {
      display: flex;
      width: 220px;

      & p {
        flex: 1;
        margin: 0 -30px;
        height: 60px;
        min-width: 160px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 120px);
        border-top: 1px solid $dark-grey;
        border-bottom: 1px solid $dark-grey;
      }

      &__button {
        border-radius: 50%;
        height: 60px;
        width: 60px;
        border-style: solid;
        border-width: 1px;
        display: block;
        z-index: 1;
      }
    }
  }
}
