.FileRightClick {
  position: fixed;
  background-color: $white;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.12);
  padding: 10px;
  border-radius: 10px;
  z-index: 10;
  max-width: 45vw;

  &__backdrop {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    z-index: 9;
  }

  &__button {
    display: flex;
    align-items: center;
    padding: 8px;
    text-align: left;
    transform: translate3d(0, 0, 0);

    @include LatoBold();

    & > svg {
      margin: 5px 10px 5px 0;
      min-width: 15px;
    }
  }
}
