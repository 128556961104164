@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.Loader {
  @include loadingWheel();

  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.2s, opacity 0.2s;
  z-index: 1;

  &--loaded {
    transform: scale(2);
    opacity: 0;
  }

  &--hasFailed {
    opacity: 0;
  }

  &--buttonLoader {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 10px;
    background: transparent;

    &::after {
      border-color: black;
    }
  }

  &--noBG {
    background-color: transparent;

    &::after {
      border: solid 2px grey;
      border-right: 0;
      border-bottom: 0;
    }
  }
}
