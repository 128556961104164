.BrandSwiperComponent {
  position: relative;
  overflow: hidden;
  padding-bottom: 30px;

  &__noBrands {
    text-align: center;
    align-self: center;
    display: block;
    justify-content: center;
    width: 100%;
    font-size: $font-size-small;
    color: $font-calendar-grey;
  }
}

.SelectedBrand__marker {
  position: absolute;
  bottom: 0;
  height: 20px;
  border-bottom: solid 3px red;
  width: 230px;
  max-width: 230px;

  @include breakpoint($sm) {
    max-width: 300px;
  }

  &__dot {
    width: 10px;
    height: 10px;
    background: red;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -25px;
    border-radius: 50%;
    display: none;

    @include breakpoint($sm) {
      display: block;
    }
  }
}
