.FileUpload {
  @include standardFormStyle();

  position: relative;
  background: white;
  display: flex;
  align-items: center;
  height: auto;
  margin-bottom: 20px;
  padding: 20px;
  padding-bottom: 10px;

  &__uploadedFiles {
    display: flex;
    flex-wrap: wrap;

    &__file {
      @include dropShadowForm();

      width: 60px;
      height: 60px;
      display: block;
      margin: 0 10px;
      margin-bottom: 10px;
      border-radius: 15px;
      overflow: hidden;
      border: solid thin;
      border-color: $form-input-outline;
      transition: transform 0.5s, opacity 1s;
      animation-name: spinIn;
      animation-duration: 0.2s;
      animation-iteration-count: 1;
      animation-timing-function: ease-out;
      position: relative;

      &:hover {
        cursor: pointer;

        .FileUpload__uploadedFiles__file__delete {
          opacity: 1;
          animation-name: spinIn;
          animation-duration: 0.2s;
          animation-iteration-count: 1;
          animation-timing-function: ease-out;
        }
      }

      &:active {
        transform: scale(0.7);
        opacity: 0.5;
      }

      &--focused {
        transform: rotate(-10deg) scale(0.9);
        box-shadow: 0;

        * {
          opacity: 0.5;
        }
      }

      &--loading {
        opacity: 0.5;
        pointer-events: none;
      }

      &__delete {
        position: absolute;
        width: 50%;
        height: 50%;
        background: $primary-blue;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        border-radius: 50%;
        opacity: 0;
        transition: opacity 0.2s;

        &::after {
          content: "+";
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          margin: auto;
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          transform: rotate(45deg);
        }
      }

      &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 0.2s;
      }

      &__document {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: $font-size-small;
        letter-spacing: 1px;
        background-color: $notifications-yellow;
        font-weight: bold;
      }
    }
  }

  &__dropzone {
    height: 60px;
    width: 120px;
    min-width: 120px;
    border: dashed thin;
    border-color: $primary-blue;
    border-radius: 15px;
    margin: 0 10px;
    position: relative;
    transition: transform 0.2s;
    margin-bottom: 10px;

    &::after {
      content: "+";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      color: $primary-blue;
      pointer-events: none;
      transition: transform 0.2s;
    }

    &--focused {
      border-style: solid;
      transform: scale(0.95);

      &::after {
        transform: scale(1.1);
      }
    }

    &--loading {
      @include loadingWheel();

      border-style: solid;
      opacity: 0.5;

      &::after {
        border-color: $primary-blue;
        opacity: 0.5;
        width: 15px;
        height: 15px;
      }
    }
  }
}

@keyframes spinIn {
  from {
    transform: rotate(360deg) scale(0);
  }

  to {
    transform: rotate(0deg) scale(1);
  }
}
