@import "./breakpoint";
@import "./fonts";
@import "./grid";

@mixin IEFix() {
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    @content;
  }

  @supports (-ms-ime-align: auto) {
    @content;
  }
}

@mixin loadingWheel {
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: solid 2px white;
    border-right: 0;
    border-bottom: 0;
    border-radius: 50%;
    width: 40%;
    height: 40%;
    margin: auto;
    animation-name: spin;
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }
}
