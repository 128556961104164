.LoginHeader {
  margin-bottom: 40px;

  &__h1 {
    color: $black;
    letter-spacing: 0.16px;
    font-size: 38px;
  }

  &__intro {
    padding: 20px 0;
  }
}
