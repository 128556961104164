.HelpSearchBar {
  position: relative;
  max-width: 500px;
  width: 90%;

  @include breakpoint($sm) {
    width: 70%;
  }

  &__input {
    padding-inline-start: 20px;
    padding-inline-end: 20px;
    width: 100%;
    min-width: 250px;
    height: 60px;
    border-radius: 30px;
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.5);
    border-style: solid;
    border-width: 2px;
    font-size: 14px;
    font-weight: 100;
    text-transform: none;
    appearance: none;

    @include LatoBold();

    @include IEFix() {
      padding: 0 20px;
    }

    &::placeholder {
      color: inherit;
      text-transform: uppercase;
    }

    &__icon {
      position: absolute;
      top: 22px;
      right: 28px;
    }
  }
}
