.NewsSlider {
  display: flex;
  min-height: 530px;
  width: 100%;
  background-color: $white;
  position: relative;
  margin-bottom: 85px;
  margin-left: 0;

  @include breakpoint($sm) {
    min-height: auto;
    height: 345px;
    margin-bottom: 50px;
    width: calc(100% + 50px);
    margin-left: 50px;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    & .Loader {
      height: 200px;
      max-width: 200px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;

    @include breakpoint($sm) {
      flex-direction: row;
    }

    &__title {
      font-size: $font-size-medium;
      line-height: 1;
    }

    &__details {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: flex-start;
      padding: 30px 30px 50px;
      max-width: 400px;
      margin: 0 auto;

      @include breakpoint($sm) {
        padding: $spacing-medium $spacing-large;
      }

      &__latest {
        @include LatoBold();
      }

      & button {
        height: 45px;
        width: 200px;
        max-width: 100%;
      }
    }

    &__excerpt {
      word-break: break-word;
    }

    &__details {
      width: 100%;
      overflow: hidden;
      order: 2;

      @include breakpoint($sm) {
        width: 55%;
        order: 1;
        margin-left: 3%;
      }

      & p {
        width: 100%;
      }
    }

    &__image {
      height: 400px;
      order: 1;
      overflow: hidden;

      &__wrap {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;

        & img {
          height: 100%;
          width: auto;
        }
      }

      @include breakpoint($sm) {
        height: auto;
        order: 2;
        width: 45%;
      }
    }

    .RoundedButton {
      width: 160px;
      margin-top: 20px;

      @include breakpoint($sm) {
        min-width: 200px;
        margin-top: 0;
      }
    }
  }

  & .swiper-container {
    width: 100%;
    overflow: visible;

    @include breakpoint($sm) {
      overflow: hidden;
    }

    & .swiper-wrapper {
      position: relative;
      display: flex;
      width: 100%;
      height: 100%;

      & .swiper-slide {
        position: relative;
        min-height: 530px;
        flex: 0 0 100%;

        @include breakpoint($sm) {
          min-height: auto;
          height: 345px;
        }
      }
    }

    & .swiper-pagination {
      width: 100%;
      display: block;
      position: relative;
      top: 44px;

      @include breakpoint($sm) {
        position: absolute;
        top: auto;
      }

      &-bullet {
        width: 12px;
        height: 12px;
        outline: none;

        @include breakpoint($sm) {
          width: 8px;
          height: 8px;
        }
      }
    }
  }
}
