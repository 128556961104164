.FileTableRow {
  @include Lato();

  display: flex;
  align-items: center;
  width: 100%;
  padding: 28px 0;
  border-bottom: 1px solid #a0a0a0;

  &--header {
    padding-bottom: 10px;
  }

  &__loader {
    width: 30px;

    & .Loader {
      height: 30px;
    }
  }

  & p {
    font-size: 14px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .FileTableView & p {
    font-size: 16px;
  }

  &__name {
    width: 65%;
    margin-right: $spacing-small;
    display: flex;
    align-items: center;

    @include breakpoint($sm) {
      width: 40%;
    }

    & svg {
      margin-right: $spacing-small;
      height: 22.5px;
      width: 30px;
      min-width: 30px;
    }

    &__text {
      margin-left: 16px;
    }
  }

  &__dots {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint($sm) {
      width: 5%;
    }
  }

  &__preview {
    margin-right: $spacing-small;
    min-width: 30px;
    min-height: 25px;

    & img {
      height: auto;
    }
  }

  &__items,
  &__lastModified {
    margin-right: 1%;
  }

  &__items {
    width: 20%;
    text-align: right;

    @include breakpoint($sm) {
      width: 14%;
      text-align: left;
    }
  }

  &__fileSize {
    width: 15%;
  }

  &__lastModified {
    width: 23%;
  }

  &__fileSize,
  &__lastModified {
    display: none;

    @include breakpoint($sm) {
      display: initial;
    }
  }
}
