@import "./components/fileAccordion";
@import "./components/fileTableRow";
@import "./components/fileView";
@import "./components/fileViewItem";
@import "./components/fileViewDocument";
@import "./components/fileRightClick";
@import "./components/filePreview";

.DigitalResources {
  padding: 20px 50px;
  margin-bottom: 30px;
  width: 100%;
  height: 100%;

  @include breakpoint($sm) {
    padding: 50px 50px 20px;
  }

  &__downloadLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    & .Loader {
      height: 150px;
      width: 150px;

      &::after {
        height: 100%;
        width: 100%;
      }
    }
  }

  &__menu {
    @include grid();
    @include grid-template(1fr, auto 1fr 0.5fr);

    width: 100%;
    max-width: 100%;
    height: unset;
    justify-content: space-between;
    align-items: center;
    background-color: unset;
    padding: 2px 0;
    margin-bottom: 0;

    @include breakpoint($sm) {
      @include grid-template(1fr, 1fr 0.5fr);

      height: 70px;
      margin-bottom: 20px;
      background-color: $white;
      padding: 2px 10px;
    }

    &__mobileBack {
      @include grid-placement(1, 1);

      padding-left: 0;
    }

    &__filePath {
      display: flex;
      justify-content: flex-start;
      width: calc(100% - 10px);
      margin-right: 10px;
      padding-left: 8px;
      overflow: hidden;
      height: 100%;

      @include grid-placement(1, 2);

      @include breakpoint($sm) {
        @include grid-placement(1, 1);
      }

      &__pathItem {
        padding: $spacing-small 5px $spacing-small 0;
        overflow: hidden;
        min-width: 65px;
        height: 100%;

        & p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        &__button {
          padding: 2px;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: left;
          width: 100%;
          height: 100%;

          &__more {
            height: 25px;
            width: 25px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: rotate(90deg);
            padding: 13px 0;
            margin-left: 2px;
          }

          & p {
            min-width: 40px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }

          &:after {
            display: inline-block;
            font-family: FontAwesome;
            content: "\003E";
            opacity: 0.5;
            margin-left: 8px;
          }
        }

        &:last-of-type button {
          &:after {
            content: none;
          }
        }

      }
    }

    &__controls {
      display: flex;
      align-items: center;
      flex: 0.5;
      justify-content: flex-end;

      @include grid-placement(1, 3);

      @include breakpoint($sm) {
        @include grid-placement(1, 2);
      }

      & .Search__container {
        display: none;
        margin-right: 16px;

        @include breakpoint($sm) {
          display: flex;
        }
      }

      &__search {
        height: 38px;
        padding: $spacing-small;
        flex: 0 1 340px;
        -webkit-border-radius: 20px;
        -webkit-appearance: none;
        appearance: none;
        box-shadow: none;
        margin-right: 10px;
        font-size: $font-size-default;
        padding-inline-start: $spacing-small;
        padding-inline-end: $spacing-default;
        border: none;

        &:focus {
          opacity: 1;
        }

        &__icon {
          height: 14px;
          width: 14px;
          margin-top: 3px;
        }
      }

      &__viewToggle {
        display: flex;

        & input {
          display: none;

          &:checked {
            & + label {
              background-color: $white;

              @include breakpoint($sm) {
                background-color: $grey;
              }
            }
          }
        }

        & label {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          width: 40px;
          background-color: $grey;
          border-radius: 50%;

          @include breakpoint($sm) {
            background-color: $white;
          }
        }
      }
    }
  }
}
