.TextInput {
  position: relative;

  &__input {
    border-color: $form-input-outline;

    &:focus {
      border-color: $form-input-outline-active;
    }

    &--hasError {
      &,
      &:focus {
        border-color: $form-error;
        color: $form-error;
      }
    }

    &--needHelp {
      margin-bottom: 0;
    }
  }

  &__label {
    transition: transform 0.2s, opacity 0.2s;
    opacity: 1;

    &--needHelp {
      position: absolute;
      top: 22px;
      margin: auto;
      display: flex;
      align-items: center;
      left: 30px;

      & .Label {
        padding: 0;

        & label {
          font-size: $font-size-small;
        }
      }
    }

    &--hide {
      transform: translateX(50px);
      opacity: 0;
    }
  }

  &--noLabel {
    margin-top: 31px;
  }
}
