.error_toast {
  font-family: Lato !important;
  background-color: $error !important;
}

.success_toast {
  font-family: Lato !important;
  background-color: $darkGreen !important;
}

.Toastify {
  &__toast--success {
    font-family: Lato !important;
    background-color: $darkGreen !important;
  }

  &__toast--error {
    font-family: Lato !important;
    background-color: $error !important;
  }
}
