@mixin minMaxBreak($min, $max) {
  @media (min-width: $min) and (max-width: $max) {
    @content;
  }
}

@mixin breakpoint($size) {
  @media (min-width: $size) {
    @content;
  }
}

@mixin showAtSm($display: block) {
  display: none;

  @include breakpoint($sm) {
    display: $display;
  }
}

@mixin showAtMd($display: block) {
  display: none;

  @include breakpoint($md) {
    display: $display;
  }
}

@mixin showUntilSm() {
  @include breakpoint($sm) {
    display: none;
  }
}
