.RegisterStudentForm {
  max-width: 800px;
  padding: 0 30px;
  margin: 80px auto;

  & input {
    background-color: $white;
  }

  &__address {
    padding: 5% 10%;
  }

  &__loaderContainer {
    height: 200px;
    display: flex;
    justify-content: center;

    & .Loader {
      max-width: 200px;
    }
  }
}
