@import "./routes/dashboard/components/contactBanner";
@import "./components/SocialFeed/socialFeed";
@import "./components/SocialFeed/socialFeedItem";

.Dashboard {
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;

  @include breakpoint($sm) {
    @include grid-placement(2, 2);

    padding: 0;
  }

  & > * {
    margin: 20px 0;

    @include breakpoint($md) {
      margin: 45px 0;
    }
  }

  & > div:last-child {
    padding-bottom: 0;
    margin-bottom: $spacing-default;
  }

  & div.ArticleSection:last-of-type {
    padding-bottom: 30px;
  }

  &__loader {
    height: 200px;
    width: 200px;
    display: flex;
    justify-content: center;

    & .Loader {
      max-width: 200px;
    }
  }

  &__brandDropdown {
    display: flex;
    justify-content: center;
    margin: 30px 10px;
    width: calc(100% - 20px);

    & .SelectInput {
      width: 100%;
    }

    @include breakpoint($sm) {
      display: none;
    }
  }

  &__agentHeader {
    width: 100%;
    height: unset;
    min-height: 429px;
    margin: 0;
    background: transparent;
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    padding: 0 20px;

    @include breakpoint($sm) {
      width: calc(100% - 50px);
      background: $white;
      margin: 0 0 50px 50px;
      padding: 0;
      flex-direction: row;
    }

    &__details {
      flex: unset;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      padding-bottom: 5%;

      @include breakpoint($sm) {
        padding: 5% 5% 5% 3%;
      }

      @include breakpoint($lg) {
        padding: 5% 13% 5% 5%;
      }

      &__brandName {
        text-transform: uppercase;
        font-size: 18px;

        @include LatoBold();
      }

      &__welcome {
        font-size: 45px;
        margin-bottom: 30px;
        margin-top: 10px;
        line-height: 1;

        @include breakpoint($sm) {
          font-size: 60px;
        }
      }
    }

    &__image {
      flex: unset;
      margin-top: 20px;
      display: block;
      background: $grey;
      position: relative;
      min-width: 40%;
      height: 380px;

      &__loader {
        &::after {
          width: 20px;
          height: 20px;
        }
      }

      & img {
        object-fit: cover;
      }

      @include breakpoint($sm) {
        height: auto;
      }

      @include breakpoint($phablet) {
        margin-top: 0;
        flex: 45%;
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  &__documents {
    width: 100%;
  }

  &__documentsHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding-bottom: 50px;

    @include breakpoint($md) {
      flex-direction: row;
    }

    & .RoundedButton {
      height: 60px;
    }

    &__inner {
      padding-bottom: $spacing-medium;

      @include breakpoint($md) {
        padding-bottom: 0;
      }

      & h1 {
        @include PlayfairBold();
      }

      & p {
        @include LatoBold();
      }
    }
  }
}

.BrandDropdown {
  @include LatoBold();

  & input {
    margin-right: 10px;
  }

  &__span {
    color: transparent;
    background-color: transparent;
    position: absolute;
    top: -40px;
    font-size: 17px;
    max-width: 90vw;

    @include LatoBold();
  }
}
