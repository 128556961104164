.NeedHelpBanner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: unset;
  width: calc(100% - 20px);
  background-color: $white;
  padding: 0;
  margin: 20px 10px 30px;
  transition: all 0.2s ease-in-out;
  transition: height 0.3s 0.2s ease-in-out;
  overflow: hidden;

  @include breakpoint($sm) {
    flex-direction: row;
    margin: 20px auto 0;
    width: 100%;
    height: 350px;
  }

  &__imageWrap {
    flex: unset;
    display: flex;
    flex-direction: column;
    max-width: unset;
    width: 100%;
    height: 100%;
    overflow: hidden;
    min-height: 200px;

    @include breakpoint($sm) {
      max-width: 40%;
      height: 100%;
      flex: 0.45;
      width: unset;
    }

    &__image {
      width: 100%;
      justify-content: center;
      display: flex;

      & img {
        width: 100%;
        height: auto;
        max-height: 350px;

        @include breakpoint($sm) {
          width: auto;
          height: 100%;
        }
      }
    }
  }

  &__details {
    position: relative;
    display: block;
    height: 100%;
    padding: 42px 20px;
    flex: 0.55;

    @include breakpoint($sm) {
      padding: 65px;
    }

    &__closeButton {
      position: absolute;
      top: 30px;
      right: 16px;
      display: none;
      align-items: center;
      justify-content: center;
      border-width: 1px;
      border-style: solid;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      transform: rotateZ(-45deg);
    }

    & p {
      margin-bottom: 5px;
    }

    &__heading {
      margin-top: 20px;
      margin-bottom: 50px;
      font-size: 35px;

      @include breakpoint($sm) {
        margin-top: 0;
      }
    }

    &__button {
      height: 50px;
      width: 183px;
      justify-content: center;
    }

    &__form {
      display: flex;
      flex-direction: column;

      & input,
      & textarea {
        height: 60px;
        width: 100%;
        max-width: 460px;
        border-radius: 30px;
        border-width: 1px;
        border-style: solid;
        border-color: inherit;
        margin-bottom: 20px;
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        color: inherit;

        &::placeholder {
          color: inherit;
        }
      }

      & textarea {
        height: 170px;
        padding: 20px;
      }
    }
  }

  &--formOpen {
    min-height: 770px;
    transition: height 0.3s ease-in-out;

    .NeedHelpBanner__details {
      &__closeButton {
        display: flex;
      }

      &__heading {
        font-size: 40px;
        margin-bottom: 40px;
      }
    }

    .NeedHelpBanner__imageWrap {
      transition: all 0.4s ease-in-out;
    }
  }

  .RoundedButton--is-form-open {
    svg {
      margin-left: 10px;
    }
  }

  .RoundedButton--is-form-closed {
    svg {
      margin-right: 10px;
    }
  }
}

.RepliesWithin {
  color: $white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 20%;
  text-align: center;

  &__box {
    width: 126px;
    height: 126px;
    border: 1px solid $white;
    padding: $spacing-small;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 20px;

    &__number {
      font-size: 80px;
      line-height: 1;
      margin-top: -30px;
    }
  }
}
