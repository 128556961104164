.QuickGuideOverlay {
  background-color: $white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  cursor: help;
  height: 100%;

  @include breakpoint($sm) {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
